import React from 'react'
import LaddrIcon from "../../assets/images/main-app/laddr-icon.png";

const LogoShow = () => {
  return (
    <div className="logo-show" style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100vh",
      padding: "1rem",
    }}>
      <img src={LaddrIcon} alt="laddr logo" />
    </div>
  )
}

export default LogoShow