import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetLessonsByUnitIdQuery } from "../../store/apiSlice";
import Exercise from "../exercise/exercise.route";
import { Spinner } from "react-bootstrap";

const Lesson = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const selectedUnitId = useSelector((state) => state.units.selectedUnit);
  const selectedLessonSerial = useSelector((state) => state.lessons.selectedLessonSerial);

  const { data: lessonsData, isLoading: isLessonsLoading } =
    useGetLessonsByUnitIdQuery(selectedUnitId);

  const lessons = lessonsData?.data ?? [];

  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);

  useEffect(() => {
    // Set the current lesson index to the selected lesson serial
    const initialLessonIndex = lessons.findIndex(lesson => lesson.serial === selectedLessonSerial);
    setCurrentLessonIndex(initialLessonIndex !== -1 ? initialLessonIndex : 0);
  }, [lessons, selectedUnitId, selectedLessonSerial]);

  const handleNextLesson = () => {
    setCurrentLessonIndex((prevIndex) =>
      prevIndex < lessons.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePreviousLesson = () => {
    setCurrentLessonIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  return (
    <div style={{
      paddingBottom: isNonMobile ? "0" : "3.5rem",
      minHeight: "100vh",
    }}>
      {lessons.length > 0 && (
        <div key={lessons[currentLessonIndex].id}>
          <div>
            <Exercise lessonId={lessons[currentLessonIndex].id} />
          </div>

        </div>
      )}
      {lessons.length === 0 && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Lesson;
