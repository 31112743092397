import React from "react";
import Navigation from "../navigation/navigation.component";
import { Outlet } from "react-router-dom";
import Footer from "../footer/footer.component";

export const RootLayout = () => {
  return (
    <div>
      <Navigation />
      <Outlet />
      <Footer />
    </div>
  );
};
