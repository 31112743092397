import React, { useState } from 'react';
import Chessboard from '../chess-board/chess-board.component';
import Feedback from '../feedback/feedback.component';
import { Chess } from 'chess.js'; // Import Chess from chess.js
import StartIcon from '../../assets/icons/board/start-icon.PNG';
import PrevIcon from '../../assets/icons/board/prev-icon.PNG';
import EndIcon from '../../assets/icons/board/end-icon.PNG';
import NextIcon from '../../assets/icons/board/next-icon.PNG';
import FlipIcon from '../../assets/icons/board/flip-icon.PNG';

const GameType = ({ exercise, exercises, handleNext, currentExerciseIndex, exerciseType }) => {
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [currentFen, setCurrentFen] = useState('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'); // Starting position
  const [isFlipped, setIsFlipped] = useState(false); // State for flipping the board

  if (!exercise) {
    return <div>No exercise available.</div>;
  }

  // Filter out game result strings
  const moves = exercise.moves.split(' ').filter(move => !["0-1", "1-0", "1/2-1/2"].includes(move) && isNaN(move));

  // Update the current FEN based on the move index
  const updateFen = (moveIndex) => {
    const newGame = new Chess(); // Start from initial position
    for (let i = 0; i < moveIndex; i++) {
      newGame.move(moves[i]);
    }
    setCurrentFen(newGame.fen()); // Update FEN string
  };

  const handleNextMove = () => {
    if (currentMoveIndex < moves.length) {
      updateFen(currentMoveIndex + 1);
      setCurrentMoveIndex(currentMoveIndex + 1);
    }
  };

  const handlePrevMove = () => {
    if (currentMoveIndex > 0) {
      updateFen(currentMoveIndex - 1);
      setCurrentMoveIndex(currentMoveIndex - 1);
    }
  };

  const handleFirstMove = () => {
    updateFen(0);
    setCurrentMoveIndex(0);
  };

  const handleLastMove = () => {
    updateFen(moves.length);
    setCurrentMoveIndex(moves.length);
  };

  const handleFlipBoard = () => {
    setIsFlipped(!isFlipped); // Toggle the flipped state
  };

  return (
    <>
      <div>
        {/* Pass the orientation prop to Chessboard */}
        <Chessboard
          fen={currentFen}
          disableAutoMoves={true}
          orientation={isFlipped ? 'black' : 'white'} // Apply orientation flip
          handleNext={handleNext}
          currentExerciseIndex={currentExerciseIndex}
          currentExercise={exercise}
          moves={moves[currentMoveIndex]}
          exerciseType={exerciseType}
          exercises={exercises}
        />

        {/* Control Buttons */}
        <div style={{ marginTop: '10px', textAlign: 'center', }}>
          <button onClick={handleFlipBoard} style={{
            margin: 10
          }}>
            <img src={FlipIcon} alt="Flip Board" style={{
              height: 25, width: 25
            }} />
          </button>
          <button onClick={handleFirstMove} style={{
            margin: 10
          }} disabled={currentMoveIndex === 0}>
            <img src={StartIcon} alt="Start Move" style={{
              height: 25, width: 25
            }} />
          </button>
          <button onClick={handlePrevMove} style={{
            margin: 10
          }} disabled={currentMoveIndex === 0}>
            <img src={PrevIcon} alt="Prev Move" style={{
              height: 25, width: 25
            }} />
          </button>
          <button onClick={handleNextMove} style={{
            margin: 10
          }} disabled={currentMoveIndex >= moves.length}>
            <img src={NextIcon} alt="Next Move" style={{
              height: 25, width: 25
            }} />
          </button>
          <button onClick={handleLastMove} style={{
            margin: 10
          }} disabled={currentMoveIndex >= moves.length}>
            <img src={EndIcon} alt="End Move" style={{
              height: 25, width: 25
            }} />
          </button>
        </div>
      </div>
      <Feedback currentExerciseIndex={currentExerciseIndex} exerciseType={exerciseType} handleNext={handleNext} exercises={exercises} feedbackColor={'yellow'} feedback={''} isCorrect={true} />

    </>
  );
};

export default GameType;
