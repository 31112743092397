import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: ["Users", "Sections", "Units", "Lessons"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.laddrchess.com/api/",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendGoogleToken: builder.mutation({
      query: ({ token, email }) => ({
        url: "users/google-signIn/",
        method: "POST",
        body: { token, email },
      }),
    }),
    postUsers: builder.mutation({
      query: ({ email, password }) => ({
        url: "users/",
        method: "POST",
        body: { email, password },
      }),
      invalidatesTags: ["Users"],
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `users/${id}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    changePassword: builder.mutation({
      query: ({ current_password, new_password }) => ({
        url: "users/change-password/",
        method: "POST",
        body: {
          current_password: current_password,
          new_password: new_password,
        },
      }),
    }),
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: "users/login/",
        method: "POST",
        body: { email: email, password: password },
      }),
    }),
    refreshToken: builder.mutation({
      query: ({ refreshToken }) => ({
        url: "users/refresh-token/",
        method: "POST",
        body: { refresh_token: refreshToken },
      }),
    }),
    resendEmail: builder.mutation({
      query: ({ email }) => ({
        url: `users/resend-email/${email}/`,
        method: "POST",
      }),
      invalidatesTags: ["Users"],
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: `users/forgot-password/${email}/`,
        method: "POST",
      }),
      invalidatesTags: ["Users"],
    }),
    resetPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: "users/reset-password/",
        method: "POST",
        body: { token: token, password: password },
      }),
    }),
    verifyEmail: builder.mutation({
      query: ({ token }) => ({
        url: "users/verify-email/",
        method: "POST",
        body: { token: token },
      }),
    }),
    getSections: builder.query({
      query: () => ({
        url: "sections",
        method: "GET",
      }),
      providesTags: ["Sections"],
    }),
    getUnitsBySectionId: builder.query({
      query: (section_id) => ({
        url: `units/`,
        method: "GET",
        params: { section_id },
      }),
      providesTags: ["Units"],
    }),
    getLessonsByUnitId: builder.query({
      query: (unitId) => ({
        url: `lessons/`,
        method: "GET",
        params: { unit_id: unitId },
      }),
      providesTags: ["Lessons"],
    }),
    getExercisesByLessonId: builder.query({
      query: (lesson_id) => ({
        url: `exercises/`,
        method: "GET",
        params: { lesson_id },
      }),
    }),
  }),
});

export const {
  useSendGoogleTokenMutation,
  usePostUsersMutation,
  useGetUserQuery,
  useChangePasswordMutation,
  useLoginMutation,
  useRefreshTokenMutation,
  useResendEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
  useGetSectionsQuery,
  useGetUnitsBySectionIdQuery,
  useGetLessonsByUnitIdQuery,
  useGetExercisesByLessonIdQuery,
} = apiSlice;
