import { Box } from '@mui/material';
import React, { useState } from 'react';
import { OptionButton, OptionsContainer } from './matching_type.styles';

const MatchingType = ({ currentExercise, handleNext }) => {
  // Separate options into numeric and alphabetic
  const numericOptions = currentExercise.options
    .filter((option) => /^\(\d+\)$/.test(option.key))
    .sort((a, b) => parseInt(a.key.replace(/[()]/g, '')) - parseInt(b.key.replace(/[()]/g, '')));

  const alphabeticOptions = currentExercise.options
    .filter((option) => /^\([A-Z]\)$/.test(option.key))
    .sort((a, b) => a.key.localeCompare(b.key));

  // State to store selected numeric option and matched pairs with color
  const [selectedNumeric, setSelectedNumeric] = useState(null);
  const [userMatches, setUserMatches] = useState({});
  const [matchColors, setMatchColors] = useState({}); // Store color for each match

  // State to store whether the check is done or not
  const [checked, setChecked] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [feedback, setFeedback] = useState(null);

  // Color palette to visually differentiate matches
  const colors = ['#FFB6C1', '#ADD8E6', '#90EE90', '#FFD700', '#FF69B4', '#87CEFA', '#98FB98', '#FFA500'];

  // Handle numeric option selection
  const handleNumericClick = (numericOption) => {
    if (!checked) {  // Disable selection after checking
      setSelectedNumeric(numericOption);
    }
  };

  // Handle alphabetic option selection and matching
  const handleAlphabeticClick = (alphabeticOption) => {
    if (selectedNumeric && !checked) {  // Disable selection after checking
      // Assign color for the current match
      const color = colors[Object.keys(userMatches).length % colors.length];

      // Update user matches and their colors
      setUserMatches({
        ...userMatches,
        [selectedNumeric.id]: alphabeticOption,
      });

      setMatchColors({
        ...matchColors,
        [selectedNumeric.id]: color,
        [alphabeticOption.id]: color,
      });

      setSelectedNumeric(null); // Clear selection after matching
    }
  };

  // Check if the user's selections are correct
  const handleCheckAnswers = () => {
    const answerCorrect = numericOptions.every((numericOption) => {
      const correctAlphabeticOption = numericOption.matched_options[0]?.key; // Correct match
      const userSelection = userMatches[numericOption.id]?.key; // User's matched alphabetic option
      return correctAlphabeticOption === userSelection;
    });

    setFeedback(answerCorrect ? 'Correct! All matches are right.' : 'Incorrect! Some matches are wrong.');
    setIsCorrect(answerCorrect);
    setChecked(true);  // Mark the check as done
  };

  // Reset the game
  const handleRetry = () => {
    setUserMatches({});
    setSelectedNumeric(null);
    setFeedback(null);
    setChecked(false);
    setMatchColors({});
  };

  return (
    <>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
        {/* Numeric options */}
        <div style={{ width: '45%', display: 'grid', justifyContent: 'center' }}>
          {numericOptions.map((numericOption) => {
            // Use the assigned color if the option is matched, otherwise use default
            const color = matchColors[numericOption.id] || 'white';

            return (
             <OptionsContainer>
                 <OptionButton
                key={numericOption.id}
                style={{
                  backgroundColor: selectedNumeric?.id === numericOption.id ? 'lightblue' : color,                                  
                }}
                onClick={() => handleNumericClick(numericOption)}
              >
                <strong>{numericOption.key.replace(/[()]/g, '')}. {numericOption.option}</strong>
              </OptionButton>
             </OptionsContainer>
            );
          })}
        </div>

        {/* Alphabetic options */}
        <div style={{ width: '45%', display: 'grid', justifyContent: 'center' }}>
          {alphabeticOptions.map((alphabeticOption) => {
            // Use the assigned color if the option is matched, otherwise use default
            const color = matchColors[alphabeticOption.id] || 'white';

            return (
              <OptionsContainer>
                <OptionButton
                key={alphabeticOption.id}
                style={{
                  backgroundColor: color,                  
                }}
                onClick={() => handleAlphabeticClick(alphabeticOption)}
              >
                <strong>{alphabeticOption.key.toLowerCase().replace(/[()]/g, '')}. {alphabeticOption.option}</strong>
              </OptionButton>
                </OptionsContainer>
            );
          })}
        </div>
      </div>

     
    </div>
     <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "30px",
        width: "100%",
        padding: "50px",
        backgroundColor: !checked ? "#ffbe00" : isCorrect ? "#4caf50" : "#ff3737", // Yellow when not checked, green/red after
      }}>

        {/* Button to check answers or retry */}
        <button
          onClick={() => {
            if (!checked) {
              handleCheckAnswers();
            } else {
              handleRetry();
            }
          }}
          style={{
            backgroundColor: !checked ? "#fff" : isCorrect ? "#4caf50" : "#ff3737",
            borderColor: !checked ? "#ffbe00" : isCorrect ? "#4caf50" : "#ff3737",
            color: !checked ? "#ffbe00" : isCorrect ? "#fff" : "#fff",
            borderRadius: "10px",
            cursor: !checked ? 'pointer' : 'not-allowed',
          }}
        >
          {checked ? 'Retry' : 'Check'}
        </button>

        {/* Feedback section */}
        {feedback && (
          <div style={{ marginTop: '10px', color: feedback === 'Correct! All matches are right.' ? '#4caf50' : '#ff3737' }}>
            {feedback}
          </div>

        )}

        {/* Button to move to next exercise */}
        <button
          onClick={() => {
            handleNext();
            handleRetry(); // Reset feedback to yellow on next exercise
          }}
          disabled={!isCorrect}
          style={{
            backgroundColor: !checked ? '#ffbe00' : isCorrect ? '#4caf50' : '#ff3737',
            borderColor: !checked ? '#ffbe00' : isCorrect ? '#4caf50' : '#ff3737',
            color: !checked ? '#ffbe00' : isCorrect ? '#fff' : '#fff',
            borderRadius: '10px',
            cursor: !isCorrect ? 'not-allowed' : 'pointer',
          }}
        >
          Next
        </button>

      </Box>
    </>
  );
};

export default MatchingType;
