import styled from "styled-components";
import LaddrBackground from "../../assets/images/background/laddr-background.png";

export const AboutContainer = styled.section`
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 200px;
`;

export const AboutContent = styled.div`
  p {
    font-family: Rubik;
    font-size: 30px;
    font-weight: 400;
    line-height: 37.4px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000000;
  }

  h1 {
    background: linear-gradient(90deg, #01152f 0%, #e4007b 100%);
    background-clip: text;
    color: transparent;
    text-align: center;
  }

  .intro {
    margin: 0 20px;
    padding-bottom: 50px;
  }

  .value {
    margin: 0 20px;
  }

  @media (max-width: 768px) {
    p {
      font-size: 20px;
      line-height: 27.4px;
      text-align: left;
    }
  }
`;
