import styled from "styled-components";

export const OptionButton = styled.button`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #fff;
  }
`;

export const OptionsContainer = styled.div`
  flex-wrap: wrap;
  margin: 20px;
  align-items: center;
`;
