import styled from "styled-components";

export const PrivacyPolicyContainer = styled.section`
  padding-bottom: 100px;
  margin: 100px 0 0 0;
`;

export const PrivacyHero = styled.div`
  background: radial-gradient(
    168.64% 470.94% at 50% 52.32%,
    #398ab8 0%,
    #000000 100%
  );
  box-shadow: 0px 4px 100px 0px #004463 inset;

  padding: 200px 0 200px 0;
  color: #fff;

  h1 {
    font-family: Times New Roman;
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-family: Times New Roman;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.6px;
    letter-spacing: 0.1em;
    text-align: center;
  }
`;

export const PrivacyContent = styled.div`
  padding-top: 100px;
  position: relative;

  p {
    text-align: left;
    padding-bottom: 10px;
  }

  h4 {
    padding-bottom: 10px;
    font-size: 25px;
  }

  .content-item {
    padding-top: 100px;
    li {
      &::marker {
        font-size: 25px !important;
        color: black;
      }
    }
  }

  .content {
    li {
      &::marker {
        font-size: 25px;
      }
    }
  }

  @media screen and (max-width: 768px) {
  

    .content {
      li {
        &::marker {
          font-size: 20px;
        }
      }
    }
  }
`;

export const PrivacyTableOfContent = styled.div`
  padding: 0 10px;

  h4 {
    font-family: Times New Roman;
    font-size: 23.4px;
    font-weight: 700;
    line-height: 38.41px;
    text-align: center;
  }

  li {
    font-family: Times New Roman;
    font-size: 18.61px;
    font-weight: 700;
    line-height: 28.3px;

    a {
      color: #000;
    }
  }
`;
