import React from "react";
import Privacy from "../../privacy";
import { Col, Container, Row } from "react-bootstrap";
import {
  PrivacyContent,
  PrivacyHero,
  PrivacyPolicyContainer,
  PrivacyTableOfContent,
} from "./privacy-policy.styles";

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <PrivacyHero className="privacy-hero">
        <Container>
          <Row>
            <Col>
              <h1>Laddrchess Ltd Privacy Policy</h1>
              <p>Last updated May 23rd, 2024</p>
            </Col>
          </Row>
        </Container>
      </PrivacyHero>
      <PrivacyContent>
        <Container>
          <Row>
            <Col>
              <div>
                {
                  <>
                    <div className="privacycontent-preamble">
                      <p>
                        {" "}
                        We at <span className="laddr-span">Laddrchess Limited </span>revere and value the trust you
                        have placed in us to handle your personal information
                        properly. We recognize the importance of information
                        privacy. 
                      </p>
                      <br />
                      <p>
                        <strong>
                          This Privacy Policy describes our policies and
                          procedures on the collection, use, and disclosure of
                          Your information when you use our service and tells
                          you about your privacy rights and how they are
                          protected.
                        </strong>
                      </p>
                    </div>

                    <div className="content">
                      <ol>
                        <li>
                          <div id="introduction" className="content-item">
                            <h4>Introduction</h4>
                            <p>
                              <span className="laddr-span">Laddrchess Ltd</span>{" "}
                              is committed to protecting the privacy and
                              security of your personal information. This
                              Privacy Policy explains how we collect, use, and
                              disclose personal information when you use our
                              website, products, and services.
                            </p>
                          </div>
                        </li>

                        <li>
                          <div
                            id="information-we-collect"
                            className="content-item"
                          >
                            <h4>Information we collect</h4>
                            <p>{Privacy.content.informationWeCollect.title}</p>
                            <ul>
                              {Privacy.content.informationWeCollect.description.map(
                                (item, index) => (
                                  <li key={index}>
                                    <p>{item}</p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </li>

                        <li>
                          <div id="use-of-information" className="content-item">
                            <h4>Use of information</h4>
                            <p>{Privacy.content.useOfInformation.title}</p>
                            <ul>
                              {Privacy.content.useOfInformation.description.map(
                                (item, index) => (
                                  <li key={index}>
                                    <p>{item}</p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </li>

                        <li>
                          <div
                            id="sharing-of-information"
                            className="content-item"
                          >
                            <h4>Sharing of information</h4>
                            <p>{Privacy.content.sharingOfInformation.title}</p>
                            <ul>
                              {Privacy.content.sharingOfInformation.description.map(
                                (item, index) => (
                                  <li key={index}>
                                    <p>{item}</p>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </li>

                        <li>
                          <div id="data-retention" className="content-item">
                            {" "}
                            <h4>Data retention</h4>
                            <p>{Privacy.content.dataRentention}</p>
                          </div>
                        </li>

                        <li>
                          <div id="security" className="content-item">
                            <h4>Security</h4>
                            <p>{Privacy.content.security}</p>
                          </div>
                        </li>

                        <li>
                          <div id="rights" className="content-item">
                            <h4>Rights</h4>
                            <p>{Privacy.content.rights}</p>
                          </div>
                          <div id="changes"></div>
                        </li>

                        <li>
                          <div
                            id="changes-to-privacy-policy"
                            className="content-item"
                          >
                            <h4>Changes to Privacy Policy</h4>
                            <p>{Privacy.content.changes}</p>
                          </div>
                        </li>

                        <li>
                          <div id="contact-us" className="content-item">
                            <h4>Contact Us</h4>
                            <p>{Privacy.content.contactUs}</p>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </>
                }
              </div>
            </Col>
            <Col lg={3}>
              <PrivacyTableOfContent>
                <h4>Table of Contents</h4>
                <ol>
                  <li>
                    <a href="#introduction">Introduction</a>
                  </li>
                  <li>
                    <a href="#information-we-collect">Information we collect</a>
                  </li>
                  <li>
                    <a href="#use-of-information">Use of information</a>
                  </li>
                  <li>
                    <a href="#sharing-of-information">Sharing of information</a>
                  </li>
                  <li>
                    <a href="#data-retention">Data retention</a>
                  </li>
                  <li>
                    <a href="#security">Security</a>
                  </li>
                  <li>
                    <a href="#rights">Rights</a>
                  </li>
                  <li>
                    <a href="#changes-to-privacy-policy">
                      Changes to Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="#contact-us">Contact Us</a>
                  </li>
                </ol>
              </PrivacyTableOfContent>
            </Col>
          </Row>
        </Container>
      </PrivacyContent>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
