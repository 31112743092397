import React, { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import InfoIcon from "../../assets/dashboard/info-icon.png";
import Units from "../units/units.component";
import { useGetSectionsQuery, useGetUnitsBySectionIdQuery } from "../../store/apiSlice";
import { Spinner } from "react-bootstrap";
import { BeginnerContainer } from "./beginner.styles";
import ProgressBar from "../../components/progress-bar/progress-bar.component";
import { useDispatch } from "react-redux";
import { setSelectedSection } from "../../store/sectionSlice";

const BeginnerPage = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedSectionSerial, setSelectedSectionSerial] = useState(null);
  const [selectedSectionId, setSelectedSectionId] = useState(null);

  const { data: sectionsData, isLoading: isSectionsLoading } = useGetSectionsQuery();
  const sections = sectionsData?.data ?? [];


  // Function to get section ID from section serial
  const getSectionIdBySerial = (serial) => {
    const section = sections.find((sec) => sec.serial === serial);
    return section ? section.id : null;
  };

  const handleSectionClick = (e, section) => {
    e.preventDefault();
    const sectionId = getSectionIdBySerial(section.serial);
    setSelectedSectionSerial(section.serial);
    setSelectedSectionId(sectionId);
  };

  const { data: unitsData, isLoading: isUnitsLoading } = useGetUnitsBySectionIdQuery(selectedSectionId, { skip: !selectedSectionId });
  const units = unitsData?.data ?? [];

  useEffect(() => {
    if (units.length > 0 && selectedSectionSerial) {
      navigate(`/climb/beginner/section/${selectedSectionSerial}/units`);
      
    dispatch(setSelectedSection(selectedSectionSerial))
    }
  }, [units, navigate, selectedSectionSerial]);

  return (
    <BeginnerContainer>
      {isSectionsLoading ? (
        <div className="spinner" style={{ textAlign: "center", margin: "200px auto", width: "100%" }}>
          <Spinner />
        </div>
      ) : (
        sections.map((section) => (
          <div className="beginner-content" key={section.serial}>
            <Box m={isNonMobile ? "1.5rem 2.5rem 0 2.5rem" : "1.5rem 0.5rem 0 0.5rem"} p="0 0 3.5rem 0">
              <Card
                sx={{
                  backgroundImage: "none",
                  background:
                    "radial-gradient(99.13% 222.22% at 50% 39.88%, #4BAB33 0%, #004040 100%)",
                  borderRadius: "0.55rem",
                  margin: "1rem",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontSize: "23.37px",
                      fontWeight: 300,
                      lineHeight: "75.81px",
                      textAlign: "left",
                    }}
                    color={theme.palette.secondary[100]}
                    gutterBottom
                  >
                    Section {section.serial}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontSize: isNonMobile ? "47.55px" : "30px",
                      fontWeight: 300,
                      lineHeight: isNonMobile ? "75.81px" : "40px",
                      textAlign: "center",
                    }}
                    color={theme.palette.secondary[100]}
                    gutterBottom
                  >
                    {section.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontSize: isNonMobile ? "47.55px" : "30px",
                      fontWeight: 300,
                      lineHeight: isNonMobile ? "75.81px" : "40px",
                      textAlign: "center",
                    }}
                    color={theme.palette.secondary[100]}
                    gutterBottom
                  >
                    {0}/{section.no_of_units} units
                  </Typography>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "2.5rem", height: "2.5rem" }}
                      src={InfoIcon}
                      alt=""
                    />
                    <div>
                      {/* <ProgressBar value={1} maxValue={totalUnits} /> */}
                    </div>
                  </Box>
                  <Typography sx={{ textAlign: "center", margin: "20px 0" }}>
                    <Button
                      sx={{
                        border: "0.83px solid #00367e",
                        background: "#fff",
                        boxShadow: "0px 3.33px 3.33px 0px #00000040",
                        color: "#069d27",
                        fontFamily: "Rubik",
                        fontSize: "23.37px",
                        fontWeight: "500",
                        lineHeight: "35.81px",
                        textAlign: "center",
                        "&:hover": {
                          color: "red",
                          backgroundColor: "white",
                        },
                      }}
                      disabled={isUnitsLoading}
                      onClick={(e) => handleSectionClick(e, section)}
                    >
                      {isUnitsLoading ? "Loading..." : "Start Learning"}
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </div>
        ))
      )}
    </BeginnerContainer>
  );
};

const Beginner = () => {
  return (
    <Routes>
      <Route index element={<BeginnerPage />} />
      <Route path="section/:serial/units/*" element={<Units />} />
    </Routes>
  );
};

export default Beginner;
