import React, { useEffect, useState } from "react";
import { OptionButton, OptionsContainer } from "./objective-type.styles";
import { Box } from "@mui/material";

const ObjectiveType = ({ options, currentExerciseIndex, handleNext, exercises }) => {
  const [answer, setAnswer] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null); // Initially set to null for yellow background
  const [checked, setChecked] = useState(null); // Track if the answer has been checked

  useEffect(() => {
    const answer_option = options.find((option) => option.is_answer);
    setAnswer(answer_option?.option);
  }, [options]);

  const handleOptionClick = (option) => {
    if (!showFeedback) {
      setSelectedOption(option);
    }
  };

  const checkAnswer = () => {
    setChecked(true); // Mark that the answer has been checked
    setShowFeedback(true);
    setIsCorrect(selectedOption?.option === answer);
  };

  const resetQuiz = () => {
    setSelectedOption(null);
    setShowFeedback(false);
    setIsCorrect(null); // Reset to yellow state
    setChecked(null); // Reset checked state
  };

  return (
    <>
      <OptionsContainer>
        {options.map((option, index) => (
          <OptionButton
            key={index}
            onClick={() => handleOptionClick(option)}
            style={{
              backgroundColor: selectedOption === option ? '#b3d9ff' : '#fff',
              borderColor: selectedOption === option ? '#007bff' : '#ccc',
            }}
            disabled={showFeedback}
          >
            {index + 1}. {option.option}
          </OptionButton>
        ))}
      </OptionsContainer>

      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "30px",
        width: "100%",
        padding: "50px",
        backgroundColor: !checked ? "#ffbe00" : isCorrect ? "#4caf50" : "#ff3737", // Yellow when not checked, green/red after
      }}>
        
        <button
          onClick={() => {
            if (!checked) {
              checkAnswer(); // Check answer when "Check" is clicked
            } else {
              resetQuiz(); // Reset everything when "Retry" is clicked
            }
          }}
          disabled={!selectedOption} // Disable the button until an option is selected
          style={{
            backgroundColor: !checked ? "#fff" : isCorrect ? "#4caf50" : "#ff3737",
            borderColor: !checked ? "#ffbe00" : isCorrect ? "#4caf50" : "#ff3737",
            color: !checked ? "#ffbe00" : isCorrect ? "#fff" : "#fff",
            borderRadius: "10px",
            cursor: !selectedOption ? "not-allowed" : "pointer",
          }}
        >
          {checked ? "Retry" : "Check"}
        </button>
        {showFeedback && (
          <p style={{color: 'white'}}>{isCorrect ? "Well done!" : "Try again..."}</p>
        )}
        <button
          onClick={() => { 
            handleNext(); 
            resetQuiz(); // Reset feedback to yellow on next exercise
          }}
          disabled={currentExerciseIndex === exercises.length - 1 || !isCorrect}
          style={{
            backgroundColor: !checked ? "#fff" : isCorrect ? "#4caf50" : "#ff3737",
            borderColor: !checked ? "#ffbe00" : isCorrect ? "#4caf50" : "#ff3737",
            color: !checked ? "#ffbe00" : isCorrect ? "#fff" : "#fff",
            borderRadius: "10px",
            cursor: (currentExerciseIndex === exercises.length - 1 || !isCorrect) ? "not-allowed" : "pointer",
          }}
        >
          Next
        </button>
      </Box>
    </>
  );
};


export default ObjectiveType;
