import React, { useState } from "react";
import { useResendEmailMutation } from "../../store/apiSlice";
import {
  LaddrLogo,
  ResendButton,
  ResendEmailContainer,
  ResendEmailForm,
  ResendForm,
  ResendSection,
} from "./resend-email.styles";
import { Spinner } from "react-bootstrap";
import LaddrIcon from "../../assets/images/main-app/laddr-icon.png";

const ResendEmail = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [resendEmail, { isLoading }] = useResendEmailMutation();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await resendEmail({ email }).unwrap();
      setMessage(response.message);
    } catch (error) {
      setMessage(error?.data?.message || "Failed to send reset password email");
    }
  };

  return (
    <ResendSection>
      <ResendEmailContainer>
        <LaddrLogo>
          <img src={LaddrIcon} alt="Laddr Logo" />
        </LaddrLogo>
        <ResendForm onSubmit={submitHandler}>
          <div>
            <h2>Do you need to get a new verification email?</h2>
            <br />
            <p>
              Enter your email address and we will send you a new verification
              email.
            </p>
          </div>

          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
            />
            <ResendButton>
              <button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  "Submit"
                )}
              </button>
            </ResendButton>
          </div>
          {emailError && <p className="error">{emailError}</p>}
          {message && <p>{message}</p>}
        </ResendForm>
      </ResendEmailContainer>
    </ResendSection>
  );
};

export default ResendEmail;
