import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoWhite from "../../assets/images/logo/logo-white.png";
import LogoBlack from "../../assets/images/logo/logo-black.png";
import {
  LogoContainer,
  NavBar,
  NavBarToggler,
  NavBarWrapper,
  NavLinks,
  NavigationContainer,
  UserIcon,
} from "./navigation.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

const Navigation = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [isOpen, setIsOpen] = useState(false);
  const [isUserLinksOpen, setIsUserLinksOpen] = useState(false);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onClickHandler = () => {
    setIsOpen(false);
    setIsUserLinksOpen(false);
  };

  const isHomePage = location.pathname === "/";

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <header>
      <Container>
        <Row>
          <Col>
            <NavigationContainer
              className={
                isScrolled
                  ? "scrolled"
                  : "" || isHomePage
                  ? "transparent-bg"
                  : ""
              }
              onClick={handleDropdownClick}
            >
              <LogoContainer className="logo-container">
                <Link to="/" onClick={onClickHandler}>
                  <img src={isScrolled ? LogoBlack : LogoWhite} alt="logo" />
                </Link>{" "}
              </LogoContainer>

              <NavBarToggler className="navbar-toggler" onClick={toggleNavbar}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="2x" />
              </NavBarToggler>

              <NavBarWrapper>
                <NavBar className={isOpen ? "navbar open" : "navbar"}>
                  <NavLinks className="nav-links">
                    <li>
                      <Link to="/features" onClick={onClickHandler}>
                        Features
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={onClickHandler}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={onClickHandler}>
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/login" onClick={onClickHandler}>
                        Sign In
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/register"
                        onClick={onClickHandler}
                        className="sign-up"
                      >
                        Sign Up
                      </Link>
                    </li>
                  </NavLinks>
                </NavBar>
              </NavBarWrapper>
            </NavigationContainer>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Navigation;
