import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  email: '',
  status: 'idle',
  message: '',
  submittedEmails: [],  // Track submitted emails
};

// Create an async thunk for the email submission
export const submitEmail = createAsyncThunk(
  'email/submitEmail',
  async (email, { getState }) => {
    const response = await fetch('https://api.laddrchess.com/api/onboarding/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to submit email');
    }

    return email;
  },
);

const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitEmail.pending, (state) => {
        state.status = 'loading';
        state.message = '';
      })
      .addCase(submitEmail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = 'Email successfully submitted!';
        state.submittedEmails.push(action.payload);
        state.email = '';
      })
      .addCase(submitEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.message = 'Connection error. Please retry';
      });
  },
});

export const { setEmail, setMessage } = emailSlice.actions;

export default emailSlice.reducer;
