import React, { useState, useEffect } from "react";
import { useGetExercisesByLessonIdQuery } from "../../store/apiSlice";
import {
  ExerciseDescription,
  ExerciseHeader,
  ExerciseSection,
} from "./exercise.styles";
import Chessboard from "../../components/chess-board/chess-board.component";
import { Box } from "@mui/material";
import QuizComponent from "../../components/objective-type/objective-type.component";
import ObjectiveType from "../../components/objective-type/objective-type.component";
import Feedback from "../../components/feedback/feedback.component";
import MatchingType from "../../components/matching-type/matching-type.component";
import GameType from "../../components/game-type/game-type.component";
import CustomChessboard from "../../components/custom-chessboard/custom-chessboard";
import ChessboardResponsiveComponent from "../../components/chess-board-responsive/chess-board-responsive.component";

const Exercise = ({ lessonId }) => {
  const {
    data: exercisesData,
    isLoading: isExercisesLoading,
    error: exercisesError,
  } = useGetExercisesByLessonIdQuery(lessonId);

  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);

  useEffect(() => {
    // Reset to the first exercise when the lesson changes
    setCurrentExerciseIndex(0);
  }, [lessonId, exercisesData]);

  if (isExercisesLoading) return <div>Loading exercises...</div>;
  if (exercisesError) return <div>Error loading exercises</div>;

  const exercises = exercisesData?.data ?? [];

  const exerciseType = exercises[currentExerciseIndex]?.type;
  
  const currentExercise = exercises[currentExerciseIndex];

  const handleNext = () => {
    setCurrentExerciseIndex((prevIndex) =>
      prevIndex < exercises.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevious = () => {
    setCurrentExerciseIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  return (
    <ExerciseSection>
      {exercises.length > 0 && (
        <>
          {exercises[currentExerciseIndex].header.toLowerCase() !== 'no header' && (
            <ExerciseHeader>
              {exercises[currentExerciseIndex].header}
            </ExerciseHeader>
          )}
          {exerciseType === "matching_type" && (
            <div style={{width: "100%"}}>             
              <MatchingType currentExercise={currentExercise} handleNext={handleNext} />            
            </div>
          )}
          {exerciseType === "objective_type" && (
            <div style={{width: "100%"}}>
              <Chessboard key={currentExerciseIndex} moves={currentExercise.moves}  fen={exercises[currentExerciseIndex].fen} handleNext={handleNext} exercises={exercises} currentExerciseIndex={currentExerciseIndex} currentExercise={currentExercise} />
              <ObjectiveType
                options={exercises[currentExerciseIndex].options} 
                handleNext={handleNext} 
                exercises={exercises} 
                currentExerciseIndex={currentExerciseIndex}
              />
            </div>
          )}
          {exerciseType === "best_move_type" && (
            <div style={{width: "100%"}}>
              {currentExercise.variant === 'illegal' ? (
                <CustomChessboard 
                key={currentExerciseIndex} 
                moves={currentExercise.moves} 
                fen={exercises[currentExerciseIndex].fen}
                handleNext={handleNext}
                exercises={exercises}
                currentExerciseIndex={currentExerciseIndex}
                 />
              ) : (
                // <Chessboard key={currentExerciseIndex} moves={currentExercise.moves} fen={exercises[currentExerciseIndex].fen} handleNext={handleNext} exercises={exercises} currentExerciseIndex={currentExerciseIndex} currentExercise={currentExercise} />
                <ChessboardResponsiveComponent key={currentExerciseIndex} moves={currentExercise.moves} fen={exercises[currentExerciseIndex].fen} handleNext={handleNext} exercises={exercises} currentExerciseIndex={currentExerciseIndex} currentExercise={currentExercise} />
              )}
             
            </div>
          )}
          {
            exerciseType === "info_type" && (
              <div style={{width: "100%"}}>                
                {
                  currentExercise.variant === 'illegal' ? (
                    <CustomChessboard 
                    key={currentExerciseIndex} 
                    moves={currentExercise.moves} 
                    fen={exercises[currentExerciseIndex].fen}
                    handleNext={handleNext}
                    exercises={exercises}
                    currentExerciseIndex={currentExerciseIndex}
                     />
                  ) : (
                    <Chessboard key={currentExerciseIndex} description={currentExercise.description} moves={currentExercise.moves} fen={exercises[currentExerciseIndex].fen} handleNext={handleNext} exercises={exercises} currentExerciseIndex={currentExerciseIndex} currentExercise={currentExercise} />
                  )
                }
                
              </div>
            )
          }
           {
            exerciseType === "game_type" && (
              <div style={{width: "100%"}}>               
                <GameType exercise={currentExercise} handleNext={handleNext} exercises={exercises} currentExerciseIndex={currentExerciseIndex} />                
              </div>
            )
          }                
        </>
      )}
      {exercises.length === 0 && <p>No exercises available for this lesson.</p>}
    </ExerciseSection>
  );
};

export default Exercise;
