import styled from "styled-components";

export const WaitListContainer = styled.div`
  width: 80%;
  height: auto;
  border-radius: 30px;
  border: 5px;
  background: radial-gradient(
    535.03% 563.86% at 46.2% 0%,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(254, 251, 251, 0.9) 8.41%,
    rgba(255, 255, 255, 0.9) 91.5%
  );
  border: 1px solid #00367e;
  margin: 100px auto;
  padding: 50px;

  .join {
    padding: 30px 0 10px 0;
    font-family: Rubik;
    font-size: 50px;
    font-weight: 500;
    line-height: 59.25px;
    letter-spacing: 0.02em;
    color: #000;
    text-align: center;
  }

  .notified {
    p {
      font-family: Rubik;
      font-size: 45px;
      font-weight: 600;
      line-height: 77.03px;
      letter-spacing: 0.02em;
      text-align: center;
      background: linear-gradient(90.81deg, #00367e -13.78%, #e4007b 58.1%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .sign-up {
    p {
      font-family: Rubik;
      font-size: 30px;
      font-weight: 400;
      line-height: 37.4px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #000;
    }
  }

  .notify {
    padding: 50px;
    justify-content: center;
    display: flex;

    input {
      border-radius: 15px;
      padding: 15px;
      width: 500px;
    }

    button {
      font-family: Rubik;
      font-size: 20px;
      font-weight: 400;
      line-height: 17.4px;
      letter-spacing: 0.02em;
      text-align: center;
      border-radius: 15px;
      background: #000;
      color: white;
      padding: 15px;
      width: auto;
      margin: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    width: auto;
    padding: 10px;
    margin: 100px 10px;

    .join {
      font-size: 30px;
      line-height: 40px;
      padding: 10px;
    }

    .notified {
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .notify {
      flex-direction: column;
      padding: 0;
      margin: 20px 0;

      input {
        height: 80px;
        border-radius: 15px;
        font-size: 16px;
        line-height: 17.4px;
        width: auto;
        padding: 10px;
        margin-bottom: 10px;
      }
      button {
        height: 80px;
        border-radius: 15px;
        font-size: 16px;
        line-height: 20.4px;
        width: auto;
        margin: 0;
      }
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .sign-up {
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
`;
