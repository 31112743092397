import styled from "styled-components";

export const ResendSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 100px;
  background-color: #f8f8f8;
  position: relative;
  padding: 100px 0;
`;

export const ResendEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  padding: 20px;
  background-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 10px;
  margin: 0 auto;
  position: relative;
  padding: 50px;
`;

export const LaddrLogo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  img {
    width: auto;
    height: 60px;
    margin-bottom: 20px;
  }
`;

export const ResendForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .email,
  .password {
    height: 90px;
  }

  .login-info {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 50px;
  }

  input {
    border-radius: 10px;
    padding: 10px;
    width: 500px;
    border: 1px solid #ccc;
    outline: none;
  }

  
  .error {
    color: red;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }

  .forgot {
    padding: 10px;
    text-align: right;
    float: right;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 100%;

    a {
      text-decoration: none;
      color: #c64c6c;
      &:hover {
        text-decoration: underline;
        transition: 0.3s ease;
      }
    }
  }

  .toggle-visibility {
    position: absolute;
    right: 10px;
    top: 62.5%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
  }

  @media screen and (max-width: 768px) {
    input {
      width: 300px;
    }
  }
`;

export const ResendButton = styled.div`
  padding: 30px;

  button {
    background: #2e8eff;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.4px;
    text-align: center;
  }

  p {
    font-size: 20px;
    line-height: 27.4px;
    text-align: center;
    padding: 3px;
  }

 
`;

