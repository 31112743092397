import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Chess } from "chess.js"; // Import chess.js
import {
    BoardContainer,
    BoardRow,
    PieceImage,
    StyledSquare,
} from "../chess-board/chess-board.styles";
import bb from "../../assets/pieces/bb.png";
import bk from "../../assets/pieces/bk.png";
import bn from "../../assets/pieces/bn.png";
import bp from "../../assets/pieces/bp.png";
import bq from "../../assets/pieces/bq.png";
import br from "../../assets/pieces/br.png";
import wb from "../../assets/pieces/wb.png";
import wk from "../../assets/pieces/wk.png";
import wn from "../../assets/pieces/wn.png";
import wp from "../../assets/pieces/wp.png";
import wq from "../../assets/pieces/wq.png";
import wr from "../../assets/pieces/wr.png";
import Feedback from "../feedback/feedback.component";
import { current } from "@reduxjs/toolkit";

const pieceImages = {
    b: { k: bk, q: bq, r: br, b: bb, n: bn, p: bp },
    w: { k: wk, q: wq, r: wr, b: wb, n: wn, p: wp },
};

const Square = ({
    children,
    isDark,
    isClickable,
    isLegalMove,
    showRowLabel,
    showColumnLabel,
    rowLabel,
    columnLabel,
    onClick
}) => {
    return (
        <StyledSquare
            $isDark={isDark}
            $isClickable={isClickable}
            $isLegalMove={isLegalMove}
            $showRowLabel={showRowLabel}
            $showColumnLabel={showColumnLabel}
            $rowLabel={rowLabel}
            $columnLabel={columnLabel}
            style={{
                backgroundColor: isLegalMove ? "rgba(0, 255, 0, 0.3)" : undefined, // Light green overlay for legal moves
            }}
            onClick={onClick}
        >
            {children}
        </StyledSquare>
    );
};


const ChessboardResponsiveComponent = ({ fen, description = '', moves, handleNext, exercises, currentExercise, currentExerciseIndex }) => {
    const [playerColor, setPlayerColor] = useState("w");
    const orientation = playerColor === "b" ? "black" : "white";

    const moveSequence = moves
    .split(" ")
    .filter(move => !move.includes("...") && !move.includes("."));

    const [currentTurn, setCurrentTurn] = useState("w");
    const [chess] = useState(new Chess(fen));
    const [position, setPosition] = useState({});
    const [selectedSquare, setSelectedSquare] = useState(null);
    const [legalMoves, setLegalMoves] = useState([]); // New state for legal moves
    const [feedback, setFeedback] = useState("");
    const [error, setError] = useState("");
    const [isCorrect, setIsCorrect] = useState(true);
    const [feedbackColor, setFeedbackColor] = useState("#ffbe00");
    const [lastMove, setLastMove] = useState(false);
    const [currentMoveIndex, setCurrentMoveIndex] = useState(0); // Adjust starting move index based on color


    useEffect(() => {
        if (moves && moves.startsWith("1...") || orientation === "black") {
            setPlayerColor("b");
            setCurrentTurn("b");
        } else {
            setPlayerColor("w");
            setCurrentTurn("w");
        }
        if (lastMove) {
            setFeedbackColor("#4caf50");
        }
        setPosition(parseFEN(chess.fen()));
    }, [fen]);

    const renderPiece = (piece) => {
        if (!piece) return null;
        const { color, type } = piece;
        return <PieceImage src={pieceImages[color][type]} alt={`${color} ${type}`} />;
    };

    const handleSquareClick = (square) => {
        if (selectedSquare) {
            movePiece(selectedSquare, square);
            setSelectedSquare(null);
            setLegalMoves([]); // Clear legal moves after move
        } else {
            const piece = position[square];
            if (piece && piece.color === currentTurn) {
                setSelectedSquare(square);
                calculateLegalMoves(square); // Calculate legal moves for the selected piece
            }
        }
    };

    const calculateLegalMoves = (square) => {
        const moves = chess.moves({ square, verbose: true });
        const squares = moves.map(move => move.to);
        setLegalMoves(squares); // Update state with legal moves
    };

    const parseFEN = (fen) => {
        const pieces = {};
        const [boardPart] = fen.split(" ");
        const ranks = boardPart.split("/");

        ranks.forEach((rank, rankIndex) => {
            let file = 0;
            for (const char of rank) {
                if (parseInt(char)) {
                    file += parseInt(char);
                } else {
                    const color = char === char.toUpperCase() ? "w" : "b";
                    const pieceType = char.toLowerCase();
                    const square = String.fromCharCode(97 + file) + (8 - rankIndex);
                    pieces[square] = { color, type: pieceType };
                    file++;
                }
            }
        });
        return pieces;
    };

     // Handle computer's move automatically
     const handleComputerMove = () => {
        const nextMoveIndex = currentMoveIndex + 1;

        if (nextMoveIndex < moveSequence.length) {
            const computerMove = moveSequence[nextMoveIndex];
            chess.move(computerMove); // Execute the computer's move
            setPosition(parseFEN(chess.fen()));
            setCurrentMoveIndex(nextMoveIndex + 1);
            setFeedback("Computer moved, your turn!");
        } else {
            setFeedback("Exercise completed! Well done!");
            setFeedbackColor("#4caf50");
            setLastMove(true)           
        }
    };

    const handlePlayerMove = (move) => {
        // First check if all moves are completed
        if (currentMoveIndex >= moveSequence.length) {
            setFeedback("Exercise completed! No more moves allowed.");
            setLastMove(true)
            return;
        }

        try {
            const moveNotation = `${move.from}${move.to}`;
            const moveResult = chess.move(moveNotation);

            if (!moveResult) {
                setFeedback("Invalid move");
                setSelectedSquare(null);
                return;
            }

            if (moveResult.san === moveSequence[currentMoveIndex]) {
                setCurrentMoveIndex(currentMoveIndex + 1);
                setFeedback("Correct move!");
                setFeedbackColor("#ffbe00");
                setPosition(parseFEN(chess.fen()));

                setTimeout(() => {
                    handleComputerMove();
                }, 1500);
            } else {
                chess.undo();
                setPosition(parseFEN(chess.fen()));
                setFeedback("Incorrect move");
                setFeedbackColor("#ff3737");
                setSelectedSquare(null);
            }
        } catch (err) {
            // console.error("Move error:", err);
            setFeedback("Invalid move");
            setSelectedSquare(null);
        }
    };

    const movePiece = (from, to) => {
        const move = { from, to };
        handlePlayerMove(move);
    };

    return (
        <div>
            <BoardContainer>
                {Array(8)
                    .fill(null)
                    .map((_, rowIndex) => (
                        <BoardRow key={rowIndex}>
                            {Array(8)
                                .fill(null)
                                .map((_, colIndex) => {
                                    const adjustedRowIndex = playerColor === "w"
                                        ? rowIndex
                                        : 7 - rowIndex; // Flip for black
                                    const adjustedColIndex = playerColor === "w"
                                        ? colIndex
                                        : 7 - colIndex; // Flip columns for black

                                    const square = String.fromCharCode(97 + adjustedColIndex) + (8 - adjustedRowIndex);
                                    const piece = position[square];
                                    const isLegalMove = legalMoves.includes(square); // Check if square is a legal move

                                    return (
                                        <Square
                                            key={square}
                                            onClick={() => handleSquareClick(square)}
                                            isDark={(adjustedRowIndex + adjustedColIndex) % 2 === 1}
                                            rowLabel={playerColor === "w" ? 8 - rowIndex : 1 + rowIndex}
                                            columnLabel={playerColor === "w" ? String.fromCharCode(97 + colIndex) : String.fromCharCode(104 - colIndex)}
                                            showRowLabel={colIndex === 0}
                                            showColumnLabel={rowIndex === 7}
                                            isLegalMove={isLegalMove} // Pass isLegalMove prop
                                        >
                                            {renderPiece(piece)}
                                        </Square>
                                    );
                                })}
                        </BoardRow>
                    ))}
            </BoardContainer>
            {error && <div className="error">{error}</div>}
            {currentExercise.type !== "objective_type" && currentExercise.type !== "game_type" ? (
                <Feedback
                    currentExerciseIndex={currentExerciseIndex}
                    handleNext={handleNext}
                    isCorrect={isCorrect}
                    exercises={exercises}
                    feedbackColor={feedbackColor}
                    feedback={feedback}
                />
            ) : null}
        </div>
    );
};

export default ChessboardResponsiveComponent;


