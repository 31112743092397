import styled from "styled-components";

export const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f0f0f0; /* Background color of the progress bar */
  border-radius: 10px;
  overflow: hidden; /* Ensures that the progress bar fill doesn't overflow */
`;

export const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #4caf50; /* Green color for progress */
`;
  
