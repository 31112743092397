import styled from "styled-components";



export const BeginnerContainer = styled.div`
   
    min-height: 100vh;

    .spinner{
        display: flex;
        bottom: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;