import styled from "styled-components";

export const VerifyEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  button {
    border: 1px solid #007bff;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
    margin: 10px;
  }

  .resend-email {
    display: flex;
  }

  .resend {
    display: none;
  }

  .show {
    display: flex;
  }

  .content{
    position: relative;
    text-align: center;
    margin-top: 200px;
  }

  .envelope-icon{
    padding: 20px;
    img{
        height: 100px;
        weight: 100px;
    }
  }
`;
