import React from "react";

const NoPage = () => {
  return (
    <div
      style={{ marginTop: "100px", paddingTop: "100px", minHeight: "150vh", textAlign: "center" }}
      className="no-page laddr-background"
    >
      <h1>Oops! This Page Doesn't Exists</h1>
      <p>Try any of these:</p>
      <div style={{textAlign: 'center'}}>
        <ul
          style={{
            listStyle: "none",
          }}
        >
          <li>
            <p>
            <a href="/">Home</a></p>
          </li>
          <li>
            <p>
            <a href="/about">About</a></p>
          </li>
          <li>
            <p>
            <a href="/puzzles">Puzzles</a></p>
          </li>
          <li>
            <p>
            <a href="/experience">Experience</a></p>
          </li>
          <li>
            <p>
            <a href="/climb">Climb</a></p>
          </li>
          <li>
            <p>
            <a href="/curriculum">Curriculum</a></p>
          </li>
          <li>
            <p>
            <a href="/accessibility">Accessibility</a></p>
          </li>
          <li>
            <p>
            <a href="/faq">FAQs</a></p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NoPage;
