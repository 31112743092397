import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useRefreshTokenMutation,
  useVerifyEmailMutation,
} from "../../store/apiSlice";
import { VerifyEmailContainer } from "./verify-email.styles";
import EnvelopeIcon from "../../assets/icons/envelope.png";
import LaddrLogo from "../../assets/images/logo/logo-black.png";

const VerifyEmail = () => {
  const user = useSelector((state) => state.user.user);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [verifyEmail] = useVerifyEmailMutation();
  const [refreshTokenSender] = useRefreshTokenMutation();

  const tokenVerify = async (token) => {
    try {
      const response = await verifyEmail({ token }).unwrap();
      if (response.status_code === 200) {
        const refreshToken = response.token.refresh_token;
        if (refreshToken) {
          try {
            await refreshTokenSender({ refreshToken }).unwrap();
          } catch (error) {
            setMessage(error.data.message);
          }
        }
        setMessage(response.message);
        navigate("/climb", { replace: true });
      }
    } catch (error) {
      if (error.status === 400) {
        setMessage(error.data.message);
      } else {
        setMessage(error.data.message);
      }
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      tokenVerify(token);
    } else {
      setMessage("");
    }
  }, [location]);

  const resendEmail = () => {
    navigate("/resend-email", { replace: true });
  };

  return (
    <VerifyEmailContainer>
      <div className="content">
        <div className="envelope-icon">
          <img src={EnvelopeIcon} alt="envelope icon" />
        </div>

        {message ? (
          <div>
            <p>{message}</p>
            <Link to="/login">Proceed to Sign In</Link>
          </div>
        ) : (
          <>
            <h1>Verify Email</h1>
            <p>
              We have sent an email to {user ? user.email : ""}, confirm your
              email to be registered on the waitlist.
            </p>
            <p>
              If you don't receive the email, please check your spam folder.
            </p>
            <div
              className="resend-email"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>
                Didn't receive the email?{" "}
                <button onClick={resendEmail}>Resend</button>
              </p>
            </div>
          </>
        )}
      </div>
    </VerifyEmailContainer>
  );
};

export default VerifyEmail;
