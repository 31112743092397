// color design tokens export

export const tokensDark = {
  black: {
    100: "#cccccc",
    200: "#999999",
    300: "#666666",
    400: "#333333",
    500: "#000000",
    600: "#000000",
    700: "#000000",
    800: "#000000",
    900: "#000000",
  },
  primary: {
    // white
    100: "#ffffff",
    200: "#ffffff",
    300: "#ffffff",
    400: "#ffffff",
    500: "#ffffff",
    600: "#cccccc",
    700: "#999999",
    800: "#666666",
    900: "#333333",
  },
  secondary: {
    // blue
    100: "#d5e8ff",
    200: "#abd2ff",
    300: "#82bbff",
    400: "#58a5ff",
    500: "#2e8eff",
    600: "#2572cc",
    700: "#1c5599",
    800: "#123966",
    900: "#091c33"
  },
  green: {
    100: "#dbeed6",
    200: "#b7ddad",
    300: "#93cd85",
    400: "#6fbc5c",
    500: "#4bab33",
    600: "#3c8929",
    700: "#2d671f",
    800: "#1e4414",
    900: "#0f220a"
  },

};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode

          primary: {
            ...tokensLight.primary,
            main: tokensDark.black[100],
            light: tokensDark.black[200],
          },
          secondary: {
            ...tokensLight.secondary,
            main: tokensDark.secondary[600],
            light: tokensDark.secondary[700],
          },
          neutral: {
            ...tokensLight.black,
            main: tokensDark.black[500],
          },
          background: {
            default: tokensDark.black[0],
            alt: tokensDark.black[100],
          },
        }
        : {
          // palette values for light mode

          primary: {
            ...tokensDark.primary,
            main: tokensDark.primary[400],
            light: tokensDark.primary[400],
          },
          secondary: {
            ...tokensDark.secondary,
            main: tokensDark.secondary[300],
          },
          neutral: {
            ...tokensDark.black,
            main: tokensDark.black[500],
          },
          background: {
            default: tokensDark.primary[600],
            alt: tokensDark.primary[500],
            alt1: tokensLight.green[500],
          },
        }),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
