import React from 'react';
import styled from 'styled-components';
import starImage from '../../assets/icons/star.png'; // Import your star image here

const StarContainer = styled.div`
  position: relative;
  display: inline-block;

  img {
    mix-blend-mode: multiply;
  }
`;

const StarImage = styled.img`
  width: 70px; /* Adjust the width of the star image */
  height: auto; /* Maintain aspect ratio */
`;

const NumberOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px; /* Adjust font size as needed */
  color: black; /* Adjust color as needed */
`;

const StarComponent = ({ number, onStarClick }) => {
  const starArray = Array.from({ length: number }, (_, index) => (
    <StarContainer key={index} onClick={() => onStarClick(index + 1)}>
      <StarImage src={starImage} alt="Star" />
      <NumberOverlay>{index + 1}</NumberOverlay>
    </StarContainer>
  ));

  return <div>{starArray}</div>;
};

export default StarComponent;
