import styled from "styled-components";

export const RegisterSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 100px;
  background-color: #f8f8f8;
  position: relative;
  padding: 100px 0;
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  padding: 20px;
  background-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 10px;
  margin: 0 auto;
  position: relative;
  padding: 50px;
`;

export const LaddrLogo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  img {
    width: auto;
    height: 60px;
    margin-bottom: 20px;
  }
`;

export const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .username,
  .email,
  .password,
  .confirm-password {
    height: 90px;
  }

  .login-info {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 50px;
  }

  input {
    border-radius: 10px;
    padding: 10px;
    width: 500px;
    border: 1px solid #ccc;
    outline: none;
  }

  .error {
    color: red;
    font-size: 12px;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
  }

  .success {
    color: green;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }

  .forgot {
    padding: 10px;
    text-align: right;
    float: right;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 100%;

    a {
      text-decoration: none;
      color: #c64c6c;
      &:hover {
        text-decoration: underline;
        transition: 0.3s ease;
      }
    }
  }

  .toggle-visibility {
    position: absolute;
    right: 30px;
    top: 44%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
  }

  .toggle-visibility-1 {
    position: absolute;
    right: 30px;
    top: 64%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
  }

  .options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 768px) {
    input {
      width: 250px;
    }

    .toggle-visibility {
      right: 50px;
      top: 42%;
    }

    .toggle-visibility-1 {
      right: 50px;
      top: 60%;
    }
  }

  .signup-with-google {
    margin-top: 10px;
    border: 1.5px solid #000000;
    background: transparent;
    color: black;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 27.4px;
    text-align: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
`;

export const SignUpButton = styled.button`
  padding: 30px;
  background: #2e8eff;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.4px;
  text-align: center;
`;

export const SignIn = styled.div`
  padding: 30px;
  p {
    font-family: Rubik;
    font-size: 20px;
    font-weight: 400;
    line-height: 32.44px;
    text-align: center;
  }
`;
