import React from "react";
import { ContactContainer } from "./contact.styles";
import Waitlist from "../../components/wait-list/wait-list.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLocationPin,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";

const Contact = () => {
  return (
    <ContactContainer className="contact-page laddr-background">
      <h1>Get in touch with us!</h1>
      <Container>
        <Row>
          <Col>
            <div className="address">
              <h2>Find us at</h2>
              <div className="location">
                <i>
                  <FontAwesomeIcon icon={faLocationPin} />
                </i>
                <p>
                  <strong>Location: {" "}</strong>67, Old Yaba Road, Adekunle, Lagos, Nigeria
                </p>
              </div>
              <div className="location">
                <i>
                  <FontAwesomeIcon icon={faLocationPin} />
                </i>
                <p><strong>Postal Code: {" "}</strong> 101245</p>
              </div>

              <div className="phone-number">
                <p>
                  <i>
                    <FontAwesomeIcon icon={faPhone} />
                  </i>
                  <strong>Telephone: {" "}</strong>
                  +234-802-079-8749
                </p>
              </div>
              <div className="phone-number">
                <p>
                  <i>
                    <FontAwesomeIcon icon={faPhone} />
                  </i>
                  <strong>Telephone: {" "}</strong>
                  +234-802-234-4401
                </p>
              </div>

              <div className="email-address">
                <p>
                  <i>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </i>
                  <strong>Email: {" "}</strong>
                  admin@laddrchess.com
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="socials">
              <h2>Follow us on social media</h2>
              <ul>
                <li>
                  <div className="x">
                    <p>
                      <i className="icon">
                        <FontAwesomeIcon icon={faXTwitter} />
                      </i>
                      <strong>X:{" "}</strong>
                      <a target="_blank" href="https://twitter.com/laddrchess">
                        @laddrchess
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="instagram">
                    <p>
                      <i className="icon">
                        <FontAwesomeIcon icon={faInstagram} />
                      </i>
                      <strong>Instagram:{" "}</strong>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/laddrchess/"
                      >
                        @laddrchess
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Waitlist />
    </ContactContainer>
  );
};

export default Contact;
