export const styles = {
  tablist: {
    padding: "1.25rem",
    display: "flex",
    flexWrap: "nowrap",
    columnGap: "5.25rem",
    cursor: "pointer",
    justifyContent: "center",
  },
  errMessage: {
    backgroundColor: "#fee2e2",
    border: "1px solid #f87171",
    color: "#b91c1c", //
    padding: "1rem", //
    borderRadius: "0.375rem",
    marginBottom: "1rem",
    position: "relative",
  },
  successMsg: {
    backgroundColor: "#d1fae5",
    border: "1px solid #34d399",
    color: "#047857",
    padding: "1rem",
    borderRadius: "0.375rem",
    marginBottom: "1rem",
    position: "relative",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    minHeight: "100vh",
  },
  innerContainer: {
    maxWidth: "500px",
  },
  profileImage: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    columnGap: "10px",
  },
  radioLabel: {
    display: "flex",
    alignItems: "center",
  },
  radioInput: {
    marginRight: "5px",
  },
  input: {
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #333",
    width: "400px",
  },
  saveButton: {
    width: "100%",
    fontWeight: "bold",
    padding: "10px 20px",
    backgroundColor: "#2E8EFF",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  deleteButton: {
    padding: "10px",
    backgroundColor: "#e74c3c",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
  },
  passwordGroup: {
    display: "flex",
    flexDirection: "column",
  },
  passwordContainer: {
    margin: "0 auto",
    padding: "16px",
  },
  cardTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  alert: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    border: "1px solid #f87171",
    backgroundColor: "#fef2f2",
    color: "#b91c1c",
    borderRadius: "4px",
  },
  icon: {
    marginRight: "8px",
    fontSize: "1.5rem",
  },
  alertText: {
    display: "flex",
    flexDirection: "column",
  },
  alertTitle: {
    fontSize: "1rem",
  },
  alertMessage: {
    fontSize: "16px",
  },
};
