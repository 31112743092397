const Terms = {
  
  useOfService:
    "You acknowledge and agree that our services are available to all users without discrimination or limitation based on age, location, or any other factor. By accessing or using our services, you agree to comply with these terms. \
    We reserve the right to terminate or restrict access to our services for any user who violates these terms or engages in prohibited conduct, as outlined herein. \
    However, barring such violations, all individuals are welcome and encouraged to utilize our services to the fullest extent permitted by law.",
  userAccounts:
    "You may need to create an account to access certain features of our services. You are responsible for maintaining the confidentiality of your account credentials and any activities under your account. \
    You agree to notify us immediately of any unauthorized use of your account.",
  payments:
    "Some of our services may require payment. By purchasing our services, you agree to pay all fees incurred in line with your account. All payments are non-refundable.",
  intellectualProperty:
    "All content on our website and in our services, including text, graphics, logos, images, and software, is the property of Laddrchess and is protected by copyright and other intellectual property laws. You may not reproduce, modify, or distribute any content from our services without our prior written consent.",
  prohibitedUsers: {
    title: 'In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:',
    list: [
      "for  any unlawful purpose;",
      "to solicit others to perform or participate in any unlawful acts;",
      "to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;",
      "to infringe upon or violate our intellectual property rights or the intellectual property rights of others;",
      "to harass, abuse, insult, harm, defame, intimidate, or discriminate based on gender, religion, ethnicity, race, age, national origin, or disability;",
      "to submit false or misleading information;", 
      "to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;",
      "to collect or track the personal information of others;", 
      "to spam, phish, pharm, pretext, spider, crawl, or scrape;",
      "for any obscene or immoral purpose; or",
      "to interfere with or circumvent the security  features of the Service or any related website, other websites, or the Internet;", 
      "to reproduce, duplicate, copy, adapt or re-sell any part of Laddrchess;",
      "to lend, donate, assign, transfer, sell or lease your account to a third-party. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.",
    ]
  },
  disclaimer: [
    "We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure or error-free.",
    "We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.",
    "You agree that from time to time we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.",
    "You expressly agree that your use of, or inability to use, the Service is at your sole risk.\
    The Service and all products and Services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.\
    To the fullest extent permitted by law, Laddrchess shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, whether incurred directly or indirectly, arising out of your use of our services."
  ],
  indemnification: "You agree to indemnify, defend and hold harmless Laddrchess and our team, partners, directors, contractors, Service providers, interns and employees, harmless from any claim or demand, including reasonable attorney’s fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.",
  termination: [
    "The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.",
    "These Terms of Service are effective unless and until terminated by either you or us.",
    "You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.",
    "If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of  termination; and/or accordingly may deny you access to our  Services (or any part thereof)."
  ],
  agreement: [
    "The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.",
    "These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitutes the entire agreement and understanding between you and us and governs your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).",
    "Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party."
  ],
  law: "These terms shall be governed by and construed in accordance with the laws of Nigeria. Any dispute arising out of or relating to these terms shall be submitted to the exclusive jurisdiction of the courts of Nigeria.",
  changes: "We reserve the right, at our sole discretion to modify or replace any of these terms through regular updates and changes to our website. By continuing to use our services after any such changes, you agree to be bound by the revised terms.",
  contact: "If you have any questions about these terms, please contact us at admin@laddrchess.com.",

};

export default Terms;
