import React, { useState, useEffect } from "react";
import {
  ForgotButton,
  ForgotContainer,
  ForgotForm,
  ForgotSection,
  LaddrLogo,
} from "./forgot-password.styles";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useForgotPasswordMutation,
  useRefreshTokenMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
} from "../../store/apiSlice";
import { Spinner } from "react-bootstrap";
import LaddrIcon from "../../assets/images/main-app/laddr-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [resetPassword, { isLoading: resetLoading }] =
    useResetPasswordMutation();
  const [verifyEmail, { isLoading: verifyLoading }] = useVerifyEmailMutation();
  const [refreshTokenSender] = useRefreshTokenMutation();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const response = await verifyEmail({ token }).unwrap();
          setPasswordShow(true);
        } catch (error) {
          if (error.status === 400) {
            setPasswordShow(true);
          } else {
            setMessage(error.data.message);
          }
        }
      })();
    }
  }, [token, verifyEmail]);

  const validatePassword = (password) =>
    password.trim().length >= 8 && !/\s/.test(password);

  const validateConfirmPassword = (password, confirmPassword) =>
    password === confirmPassword;

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordError(
      validatePassword(newPassword)
        ? ""
        : "Password must be at least 8 characters long. No spaces in password"
    );
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setConfirmPasswordError(
      validateConfirmPassword(password, newConfirmPassword)
        ? ""
        : "Passwords do not match"
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long. No spaces in password"
      );
      return;
    }

    if (!validateConfirmPassword(password, confirmPassword)) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    if (token) {
      try {
        const response = await resetPassword({ token, password }).unwrap();
        if (response.status_code === 200) {
          
          const refreshToken = response.token.refresh_token;
          if (refreshToken) {
            try {
              await refreshTokenSender({
                refreshToken,
              }).unwrap(); 
            } catch (error) {
              setMessage(error.data.message)
            }
          }

          setMessage(response.message);
          navigate("/login");
        } else {
          setMessage(
            "Reset password failed. Please check your details and try again."
          );
        }
      } catch (error) {
        setMessage(error.data.message)
      }
    }
  };

  const clickHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword({ email }).unwrap();
      setMessage(response.message);
      navigate(location.pathname, { replace: true });
    } catch (error) {
      setMessage(error?.data?.message || "Failed to send reset password email");
    }
  };

  return (
    <ForgotSection>
      <ForgotContainer>
        <LaddrLogo>
          <img src={LaddrIcon} alt="Laddr Logo" />
        </LaddrLogo>

        <ForgotForm onSubmit={submitHandler}>
          {!passwordShow && (
            <div className="content">
              <h2>Forgot Password?</h2>
              <p>Easy fix! We’ll send you a reset email</p>
              <div className="input-field">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <ForgotButton>
                  <button onClick={clickHandler}>Reset Password</button>
                </ForgotButton>
              </div>
            </div>
          )}

          {(isLoading || verifyLoading) && <Spinner />}

          {passwordShow && (
            <div className="set-password show">
              <h2>Set new password</h2>
              <p>Must be at least 8 characters</p>
              <div className="password">
                <div style={{ position: "relative", width: "fit-content" }}>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Enter your password"
                    style={{ paddingRight: "40px" }}
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                    />
                  </span>
                </div>
              </div>
              <div className="confirm-password">
                <div style={{ position: "relative", width: "fit-content" }}>
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    placeholder="Enter your password"
                    style={{ paddingRight: "40px" }}
                  />
                  <span
                    onClick={toggleConfirmPasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={confirmPasswordVisible ? faEyeSlash : faEye}
                    />
                  </span>
                </div>
              </div>
              <ForgotButton>
                <button type="submit">
                  {resetLoading ? <Spinner /> : "Set Password"}
                </button>
              </ForgotButton>
            </div>
          )}
        </ForgotForm>
        <div>
          {passwordError && <p>{passwordError}</p>}
          {confirmPasswordError && <p>{confirmPasswordError}</p>}
          {message && <p>{message}</p>}
        </div>
      </ForgotContainer>
    </ForgotSection>
  );
};

export default ForgotPasswordPage;
