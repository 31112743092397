import styled from "styled-components";

export  const FeedbackMessage = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export const ChessboardContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
`;