import React, { useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
  HighlightOffRounded,
} from "@mui/icons-material";
import FlexBetween from "../../scences/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "../../store/globalSlice";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BackArrowButton from "../back-arrow/back-arrow.component";

const DashboardHeader = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const location = useLocation();
  const isLessonPage = location.pathname.includes("lesson");

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
        borderBottom: "1px solid grey",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* Left Side */}
        <FlexBetween>
          {isNonMobile && !isLessonPage ? (
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <MenuIcon />
            </IconButton>
          ) : null}
        </FlexBetween>
        <FlexBetween>
          {location.pathname !== "/climb" && <BackArrowButton />}
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
