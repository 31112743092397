import React, { useState } from "react";
import { styles } from "./user.style";
import { useForm } from "react-hook-form";
import { useChangePasswordMutation } from "../../store/apiSlice";
// import { motion, AnimatePresence } from "framer-motion";

const tabList = ["Account", "Billing"];

const User = () => {
  const [tab, setTab] = useState(0);

  return (
    <div className="relative">
      <div style={styles.tablist}>
        {tabList.map((item, index) => (
          <h4
            key={`tab-${index}`}
            // onClick={() => setTab(index)}
            style={
              tab === index
                ? {
                    color: "rgb(96 165 350)",
                  }
                : {}
            }
          >
            {item}
          </h4>
        ))}
      </div>
      <div className="overflow-y-scroll">
        {tab === 0 ? <AccountSettings /> : <BillingSettings />}
      </div>
    </div>
  );
};

const AccountSettings = () => {
  const [credentials, setCredentials] = useState({
    gender: "male",
    email: "",
    username: "",
    curr_password: "",
    new_password: "",
    new_password_confirm: "",
  });

  const handleInputChange = (key, value) => {
    setCredentials({ ...credentials, [key]: value });
  };

  const handleDeleteAccount = () => {
    console.log("Account deletion requested");
  };

  const [updating, setUpdating] = useState(false);

  const {
    register: updatePassword,
    handleSubmit: handlePasswordUpdate,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const [updatePwdErr, setUpdatePwdErr] = useState("");
  const [updatePwdSuccess, setUpdatePwdSuccess] = useState(false);

  const [changePassword] = useChangePasswordMutation();
  const onPasswordUpdate = async (data) => {
    setUpdating(true);
    try {
      const response = await changePassword({
        current_password: data?.current_password,
        new_password: data?.new_password,
      });

      if (response.error) {
        // console.log(response);
        setUpdatePwdErr(response?.error.data?.message);
      }

      if (!response?.error) {
        setUpdatePwdSuccess(true);
        reset();
      }
    } catch (err) {
      // console.log("Error updating password", err);
      setUpdatePwdErr("Error updating password");
    } finally {
      setUpdating(false);
    }
  };

  const newPassword = watch("new_password");

  return (
    <div style={styles.container}>
      <img
        src="https://placehold.co/100"
        alt="user profile"
        style={styles.profileImage}
      />
      <div style={styles.innerContainer}>
        <form onSubmit={() => {}} style={styles.form}>
          <div style={styles.radioGroup}>
            <span style={{}}>Gender</span>
            <label style={styles.radioLabel} htmlFor="">
              <input
                type="radio"
                value="male"
                checked={credentials.gender === "male"}
                onChange={(e) => handleInputChange("gender", e.target.value)}
                style={styles.radioInput}
              />
              Male
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                value="female"
                checked={credentials.gender === "female"}
                onChange={(e) => handleInputChange("gender", e.target.value)}
                style={styles.radioInput}
              />
              Female
            </label>
          </div>

          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={credentials.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            style={styles.input}
          />
          <label htmlFor="">Username</label>

          <input
            type="text"
            value={credentials.username}
            onChange={(e) => handleInputChange("username", e.target.value)}
            placeholder="example.com/alexa_robert"
            style={styles.input}
          />

          <button type="submit" style={styles.saveButton}>
            Save
          </button>
        </form>

        {/* Update pwd form */}
        <form onSubmit={handlePasswordUpdate(onPasswordUpdate)}>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
              }}
            >
              Change Password
            </h5>
            <span>Update your password associated with your account.</span>
          </div>

          <div>
            {updatePwdErr && (
              <ResponseMessage
                message={"⚠️ " + updatePwdErr}
                style={styles.errMessage}
              />
            )}
            {updatePwdSuccess && (
              <ResponseMessage
                message={"Password updated successfully"}
                style={styles.successMsg}
              />
            )}
            <div style={styles.passwordGroup}>
              <label htmlFor="curr_password">Current Password</label>
              <input
                type="password"
                name="curr_passowrd"
                style={{ ...styles.input }}
                id="curr_password"
                onChange={(e) =>
                  handleInputChange("curr_password", e.target.value)
                }
                {...updatePassword("current_password", {
                  required: "Current password is required",
                })}
              />
              {errors.current_password && (
                <span style={styles.errMessage}>
                  {errors.current_password.message}
                </span>
              )}
            </div>

            <div style={styles.passwordGroup}>
              <label htmlFor="new_password">New Password</label>
              <input
                type="password"
                id="new_password"
                name="new_password"
                style={styles.input}
                onChange={(e) =>
                  handleInputChange("new_password", e.target.value)
                }
                {...updatePassword("new_password", {
                  required: "New password is required",
                  minLength: {
                    value: 6,
                    message: "New password must be at least 6 characters",
                  },
                })}
              />
              {errors.new_password && (
                <span style={styles.errMessage}>
                  {errors.new_password.message}
                </span>
              )}
            </div>

            <div style={styles.passwordGroup}>
              <label htmlFor="new_password_confirm">Confirm New Password</label>
              <input
                type="password"
                name="new_password_confirm"
                id="new_password_confirm"
                onChange={(e) =>
                  handleInputChange("new_password_confirm", e.target.value)
                }
                style={styles.input}
                {...updatePassword("new_password_confirm", {
                  required: "Please confirm your new password",
                  validate: (value) =>
                    value === newPassword || "Passwords do not match",
                })}
              />
              {errors.new_password_confirm && (
                <span style={styles.errMessage}>
                  {errors.new_password_confirm.message}
                </span>
              )}
            </div>
          </div>

          <button type="submit" style={styles.saveButton} disabled={updating}>
            {updating ? "Saving..." : "Save"}
          </button>
        </form>
      </div>

      <div>
        <h6
          style={{
            fontWeight: "bold",
          }}
        >
          Delete my account
        </h6>
        <span>
          No longer want to use our service? You can delete your account here.
          This action is not reversible or recoverable. All information related
          to this account will be deleted permanently.
        </span>
      </div>
      <button onClick={handleDeleteAccount} style={styles.deleteButton}>
        Yes, delete my account
      </button>
    </div>
  );
};

// function ErrPasswordUpdate() {
//   return (
//     <div style={styles.passwordContainer}>
//       <div style={styles.cardContent}>
//         <div style={styles.alert}>
//           <span style={styles.icon}>⚠️</span>
//           <div style={styles.alertText}>
//             <strong style={styles.alertTitle}>Error</strong>
//             <span style={styles.alertMessage}>
//               We encountered an issue while trying to update password. This
//               could be due to a network error or a temporary server problem.
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

const ResponseMessage = ({ message, style }) => (
  // <AnimatePresence>
   <>
    {message && (
      // <motion.div
      //   initial={{ opacity: 0, y: -50 }}
      //   animate={{ opacity: 1, y: 0 }}
      //   exit={{ opacity: 0, y: -50 }}
      //   transition={{ duration: 0.3 }}
      //   style={style}
      //   role="alert"
      // >
      //   <span className="block sm:inline">{message}</span>
      // </motion.div>
      <span className="block sm:inline">{message}</span>
    )}

   </>
  // </AnimatePresence>

);

const BillingSettings = () => {
  return <div></div>;
};

export default User;
