import React, { useState, useEffect } from "react";
import LaddrLogo from "../../assets/images/logo/logo-black.png";
import ClimbIcon from "../../assets/icons/sidebar/Ladder.png";
import PuzzleIcon from "../../assets/icons/sidebar/New Puzzle.png";
import ShopIcon from "../../assets/icons/sidebar/New Store.png";
import LeaderIcon from "../../assets/icons/sidebar/Leaderboard.png";
import QuestIcon from "../../assets/icons/sidebar/Goal.png";
import MoreIcon from "../../assets/icons/sidebar/Down-Button.png";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/user/userSlice";
import FlexBetween from "../../scences/FlexBetween";
import { MobileDrawer } from "./dashboard-sidebar.styles";
import { authTokenActions } from "../../store/authTokenSlice";

const navItems = [
  { text: "Climb", icon: <img src={ClimbIcon} alt="Climb" /> },
  { text: "Puzzle", icon: <img src={PuzzleIcon} alt="Puzzle" /> },
  { text: "Shop", icon: <img src={ShopIcon} alt="Shop" /> },
  { text: "Leaderboard", icon: <img src={LeaderIcon} alt="Leaderboard" /> },
  { text: "Quest", icon: <img src={QuestIcon} alt="Quest" /> },
  { text: "More", icon: <img src={MoreIcon} alt="More" /> },
];

const DashboardSidebar = ({
  isNonMobile,
  isSidebarOpen,
  setIsSidebarOpen,
  drawerWidth,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useSelector((state) => state.user.user);
  const location = useLocation();
  const isLessonPage = location.pathname.includes("lesson");
  const [showLogoutButton, setShowLogoutButton] = useState(false);

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleLogout = () => {
    dispatch(authTokenActions.deleteToken());
    dispatch(logout());
    navigate("/");

    // window.location.reload();
  };

  return (
    <Box component="nav">
      {isNonMobile ? (
        <>
          {!isLessonPage && isSidebarOpen && (
            <Drawer
              open={isSidebarOpen}
              onClose={() => setIsSidebarOpen(false)}
              variant="persistent"
              anchor="left"
              sx={{
                width: drawerWidth,
                "& .MuiDrawer-paper": {
                  color: theme.palette.secondary[200],
                  backgroundColor: theme.palette.background.alt,
                  boxSizing: "border-box",
                  width: drawerWidth,
                  borderRight: "1px solid grey",
                },
              }}
            >
              <Box width="100%">
                <Box m="1.5rem 2rem 2rem 3rem">
                  <FlexBetween color={theme.palette.secondary.main}>
                    <img
                      style={{ width: "200px", height: "80px" }}
                      src={LaddrLogo}
                      alt="Laddr Logo"
                    />
                    {!isNonMobile && (
                      <IconButton
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <ChevronLeft />
                      </IconButton>
                    )}
                  </FlexBetween>
                </Box>

                <List>
                  {navItems.map(({ text, icon }) => {
                    const lcText = text.toLowerCase();
                    return (
                      <ListItem
                        key={text}
                        disablePadding
                        sx={{ borderTop: "1px solid #000" }}
                      >
                        <ListItemButton
                          onClick={() => {
                            navigate(`/${lcText}`);
                            setActive(lcText);
                          }}
                          sx={{
                            backgroundColor:
                              active === lcText
                                ? theme.palette.secondary[300]
                                : "transparent",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[100],
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              ml: "2rem",
                              height: "3rem",
                              width: "3rem",
                              color:
                                active === lcText
                                  ? theme.palette.primary[600]
                                  : theme.palette.secondary[200],
                            }}
                          >
                            {icon}
                          </ListItemIcon>
                          <Link to={`/${lcText}`}>
                            <ListItemText primary={text} />
                          </Link>
                          {active === lcText && (
                            <ChevronRightOutlined sx={{ ml: "auto" }} />
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>

              <Box
                position="relative"
                bottom="2rem"
                m="2.5rem 0"
                sx={{ borderTop: "1px solid #000" }}
              >
                <Divider />
                <FlexBetween
                  textTransform="none"
                  gap="1rem"
                  m="1.5rem 2rem 0 3rem"
                >
                  {user?.email && (
                    <Box textAlign="left">
                      <Typography
                        fontWeight="bold"
                        fontSize="0.9rem"
                        sx={{ color: "black" }}
                      >
                        {user.email}
                      </Typography>
                    </Box>
                  )}

                  <Box>
                    <SettingsOutlined
                      sx={{
                        color: theme.palette.secondary[300],
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowLogoutButton(!showLogoutButton)}
                    />
                    {showLogoutButton && (
                      <Button
                        onClick={handleLogout}
                        sx={{
                          backgroundColor: theme.palette.secondary[300],
                          color: theme.palette.primary[600],
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          mt: "0.5rem",
                          position: "absolute",
                          display: "block",
                          right: "1rem",
                        }}
                      >
                        Logout
                      </Button>
                    )}
                  </Box>
                </FlexBetween>
              </Box>
            </Drawer>
          )}
        </>
      ) : !isLessonPage ? (
        <MobileDrawer>
          {navItems.map(({ text, icon }) => {
            const lcText = text.toLowerCase();
            return (
              <ListItem
                key={text}
                disablePadding
                sx={{ borderTop: "1px solid #000", display: "flex" }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate(`/climb`);
                    setActive(lcText);
                  }}
                  sx={{
                    backgroundColor:
                      active === lcText
                        ? theme.palette.secondary[300]
                        : "transparent",
                    color:
                      active === lcText
                        ? theme.palette.primary[600]
                        : theme.palette.secondary[100],
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      height: "2.5rem",
                      minWidth: 0,
                      width: "62px !important",
                      borderRight: "1px solid #000",
                      justifyContent: "center",
                      color:
                        active === lcText
                          ? theme.palette.primary[600]
                          : theme.palette.secondary[200],
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            );
          })}
        </MobileDrawer>
      ) : null}
    </Box>
  );
};

export default DashboardSidebar;
