import styled from "styled-components";

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: #000;
  height: 100px;
  background: #000; 

  a { 
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    &:hover {
      color: #c64c6c;
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    
    a{
      @media screen and (max-width: 768px) {    
        padding: 10px;
        height: 100px;
        box-shadow: none;
      }
  

    /* Desktop styles */
    @media (min-width: 769px) {
      
        background: transparent;
        padding: 20px;
      

      nav ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        justify-content: center;
      }

      nav ul li {
        margin: 0 10px;
      }
    }
      }


      
  }
`;

export const LogoContainer = styled.div`
  img {
    width: 125px;
    height: 100px;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const NavBarWrapper = styled.div`
  .open {
    display: block;
    flex-direction: column;
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #000;
    left: 50%;
  }
`;

export const UserIcon = styled.div`
  color: #fff;

  i {
    cursor: pointer;
  }

  .user-icon-links {
    display: none;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;  

      li {
        margin: 10px 0;

        a {
          text-decoration: none;
          color: inherit;

          &:hover {
            color: #c64c6c;
            text-decoration: underline;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
          }
        }

      }
    }

  }

  .open{
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    width: 150px;
    padding: 10px;
    background-color: #000;
    transition: all 0.3s ease-in-out;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin: 10px 0;
      }
    }
  }
`;

export const NavBarToggler = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: inline;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    z-index: 999;
    position: absolute;
    right: 20px;
    color: #fff;
  }
`;

export const NavBar = styled.nav`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
    z-index: 999;
    ul {
      list-style-type: none;
      padding: 10px;
      margin: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      li {
        margin-right: 10px;
      }
    }
  }
`;

export const NavLinks = styled.ul`
  display: flex;
  gap: 50px;
  margin: 0;
  li {
    list-style: none;
    }
  }
`;
