import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";


export const LessonSlice = createSlice({
    name: "lessons",
    initialState: {
        lessons: null,
        selectedLessonSerial: null
    },
    reducers: {
        setLessons: (state, action) => {
            state.lessons = action.payload
        },
        setSelectedLessonSerial: (state, action) => {
            state.selectedLessonSerial = action.payload
        }
    },

    extraReducers: (builder) => {
        builder
        .addMatcher(
            apiSlice.endpoints.getLessonsByUnitId.matchFulfilled,
            (state, action) => {
                state.lessons = action.payload
            }
        )
    }

})

export const { setLessons, setSelectedLessonSerial } = LessonSlice.actions

export default LessonSlice.reducer