import styled from "styled-components";

export const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #333;
  background-color: #ecf1f8;
  width: 400px;
  margin: 30px auto;

  @media (max-width: 768px) {
    width: 280px;
  }
`;

export const BoardRow = styled.div`
  display: flex;
`;



export const StyledSquare = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.$isDark ? '#9aa4cf' : '#ecf1f8')};
  border: 1px solid #333;
  cursor: ${props => (props.$isClickable ? 'pointer' : 'default')};
  position: relative;
  ${props => props.$isLegalMove && `
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 255, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  `}
  &::before {
    content: ${props => (props.$showRowLabel ? `'${props.$rowLabel}'` : "''")};
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 12px;
    color: #333;
    z-index: 2;
  }
  &::after {
    content: ${props => (props.$showColumnLabel ? `'${props.$columnLabel}'` : "''")};
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
    color: #333;
    z-index: 2;
  }
  @media (max-width: 768px) {
    width: 37px;
    height: 37px;
  }
`;


export const Label = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  @media (max-width: 768px) {
    width: 37px;
    height: 37px;
  }
`;

export const PieceImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  z-index: 2;
`;

export const Popup = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffdddd;
  border: 1px solid #ff0000;
  padding: 10px;
  z-index: 1000;
  display: ${props => (props.show ? 'block' : 'none')};
`;

export const ResultMessage = styled.div`
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const ResetButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
`;

export const PromotionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const PromotionOption = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;