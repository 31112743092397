// src/redux/authTokenSlice.js
import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const initialState = {
  authorized: false,
  token: null,
  userId: null,
};

const authTokenSlice = createSlice({
  name: "authToken",
  initialState,
  reducers: {
    addToken(state, action) {
      const token = action.payload;
      const decode = jwt_decode(token);

      const id = decode.id;
      state.token = token;
      state.userId = id;
      state.authorized = true;
    },
    deleteToken(state) {
      state.token = null;
      state.userId = null;
      state.authorized = false;
    },
  },
});

export const authTokenActions = authTokenSlice.actions;

export default authTokenSlice;
