import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  FOoterBottom,
  FooterBottom,
  FooterContainer,
  FooterList,
  LogoContainer,
} from "./footer.styles";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo/logo-white.png";
import { Link } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col>
            <LogoContainer className="logo">
              <Link to='/'>
                <img src={Logo} alt="logo" />
              </Link>
            </LogoContainer>
          </Col>
          <Col>
            <FooterList className="footer-list">
              <h3>
                <Link to="/features" />
                Features
              </h3>
              <ul>
                <li>
                  <Link to="/accessibility">Accessibility</Link>
                </li>
                <li>
                  <Link to="/climb">Climb</Link>
                </li>
                <li>
                  <Link to="/curriculum">Unique Curriculum</Link>
                </li>
                <li>
                  <Link to="/experience">Gamified Experience</Link>
                </li>
                <li>
                  <Link to="/puzzles">Puzzles</Link>
                </li>
              </ul>
            </FooterList>
          </Col>

          <Col>
            <FooterList className="footer-list">
              <h3>Company</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/#faq">FAQs</Link>
                </li>
              </ul>
            </FooterList>
          </Col>

          <Col>
            <FooterList className="footer-list">
              <h3>Resources</h3>
              <ul>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/terms">Terms of service</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy policy</Link>
                </li>
              </ul>
            </FooterList>
          </Col>
        </Row>

        <Row>
          <Col>
            <FooterBottom className="footer-bottom">
              <div className="terms-and-privacy">
                <p>
                  <Link to="/terms">Terms of Service.</Link>
                </p>
                <p>
                  <Link to="/privacy">Privacy Policy.</Link>
                </p>
              </div>

              <div className="copyright">
                <p>© 2024 LADDR. All rights reserved.</p>
              </div>
              <div className="socials">
                <ul>
                  <li className="twitter">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://x.com/laddrchess?s=11"
                    >
                      <i className="icon">
                        <FontAwesomeIcon icon={faXTwitter} />
                      </i>
                    </Link>
                  </li>
                  <li className="instagram">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://www.instagram.com/laddr.inc?igsh=MTE4OGdpMHh1czFqOA=="
                    >
                      <i className="icon">
                        <FontAwesomeIcon icon={faInstagram} />
                      </i>
                    </Link>
                  </li>
                  <li className="e-mail">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="mailto:Admin@laddrchess.com"
                    >
                      <i className="icon">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </i>
                    </Link>
                  </li>
                </ul>
              </div>
            </FooterBottom>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
