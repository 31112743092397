import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  loading: false,
  error: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiSlice.endpoints.postUsers.matchFulfilled,
        (state, action) => {
          state.user = action.payload.user;
          state.isAuthenticated = false;
          state.loading = false;
        }
      )
      .addMatcher(apiSlice.endpoints.login.matchFulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addMatcher(
        apiSlice.endpoints.getUser.matchFulfilled,
        (state, action) => {
          state.user = action.payload;
          state.loading = false;
        }
      )
      .addMatcher(apiSlice.endpoints.changePassword.matchFulfilled, (state) => {
        state.loading = false;
      })
      .addMatcher(
        apiSlice.endpoints.refreshToken.matchFulfilled,
        (state, action) => {
          state.token = action.payload.token;
          state.loading = false;
        }
      )
      .addMatcher(apiSlice.endpoints.postUsers.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(apiSlice.endpoints.login.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(apiSlice.endpoints.getUser.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(apiSlice.endpoints.changePassword.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(apiSlice.endpoints.refreshToken.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        apiSlice.endpoints.postUsers.matchRejected,
        (state, action) => {
          state.error = action.error;
          state.loading = false;
        }
      )
      .addMatcher(apiSlice.endpoints.login.matchRejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addMatcher(apiSlice.endpoints.getUser.matchRejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addMatcher(
        apiSlice.endpoints.changePassword.matchRejected,
        (state, action) => {
          state.error = action.error;
          state.loading = false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.refreshToken.matchRejected,
        (state, action) => {
          state.error = action.error;
          state.loading = false;
        }
      );
  },
});

export const { logout } = usersSlice.actions;

export default usersSlice.reducer;
