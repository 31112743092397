// BackArrowButton.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack, HighlightOffRounded } from '@mui/icons-material';

const BackArrowButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

  if (location.pathname === '/climb') {
    return null;
  }

  return (
    <button onClick={goBack} style={buttonStyle}>
      <HighlightOffRounded />    
    </button>
  );
};

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  color: 'black',
  cursor: 'pointer',
  fontSize: '16px',
};

export default BackArrowButton;

