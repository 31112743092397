import styled from "styled-components";

export const ExerciseSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const ExerciseHeader = styled.h2`
  font-family: Rubik;
  font-size: 22px;
  font-weight: 600;
  line-height: 24.18px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 20px;
    line-height: 23.7px;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
  }
`;

export const ExerciseDescription = styled.div`
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  text-align: center;

  p {
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.7px;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 20px;
    line-height: 23.7px;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
  }
`;
