const Privacy = {
  content: {
    introduction:
      "is committed to protecting the privacy and security of your personal information. \
      This Privacy Policy explains how we collect, use, and disclose personal information when you use our website, products, and services.",
    informationWeCollect: {
      title:
        "We may collect the following types of personal information when you use our services:",
      description: [
        'Information you provide to us, such as your name, email address, country, and gender.',
        'Information automatically collected when you use our services, such as your IP address, device information, and usage data.',
        'Information collected from third-party sources, when you choose to connect your account with our services.'
      ],
    },
    useOfInformation: {
      title: "We may use the personal information we collect for the following purposes:",
      description: [
       'To provide and improve our services, including personalized content and recommendations.,',
       'To communicate with you about your account, transactions, and customer support inquiries.',
       'To personalize and optimize your experience on our website and in our services.',
       'To send you promotional offers, newsletters, and other marketing communications, where permitted by law.',
      ]
    },
    sharingOfInformation: {
      title: "We may share your personal information with third parties for the following purposes:",
      description: [
        'With service providers who assist us in providing and improving our services.',
        'With business partners and affiliates for marketing and promotional purposes.',
        'With legal authorities or as required by law to comply with legal obligations or protect our rights and interests.',
      ]
    },
    dataRentention: "We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.",
    security: "We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
    rights: "You have certain rights regarding your personal information, including the right to access, rectify, or erase your personal data. Please contact us using the information provided below to exercise your rights.",
    changes: "We reserve the right to modify or update this Privacy Policy at anytime. \
        We will notify you of any material changes by posting the revised policy on our website or by other means as required by law.",
    contactUs: "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at admin@laddrchess.com.",
  },
};

export default Privacy;
