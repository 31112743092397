import { Box } from '@mui/material'
import React, { useEffect } from 'react'

const Feedback = ({ currentExerciseIndex, exerciseType, handleNext, isCorrect, exercises, feedbackColor, feedback }) => {
 
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "space-between",
      marginTop: "30px",
      width: "100%",
      padding: "50px",
      backgroundColor: feedbackColor
    }}>
      <button onClick={null} disabled style={{
        backgroundColor: "#fff",
        color: "#ffbe00",
        borderRadius: "10px",
        opacity: 0.5,
        cursor: 'not-allowed'
      }} >
        Check
      </button>

      <p style={{ color: "#fff" }}>{feedback}</p>
      <button
        onClick={handleNext}
        disabled={currentExerciseIndex === exercises.length - 1}
        style={{
          backgroundColor: "#fff",
          color: "#ffbe00",
          borderRadius: "10px",
          opacity: currentExerciseIndex === exercises.length - 1 ? 0.5 : 1,
          cursor: currentExerciseIndex === exercises.length - 1 ? 'not-allowed' : 'pointer'
        }}
      >
        Next
      </button>
    </Box>

  )
}

export default Feedback