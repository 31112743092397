import styled from "styled-components";

export const TermsContainer = styled.section`
  padding-bottom: 100px;
`;

export const TermsHero = styled.div`
  background: radial-gradient(
    168.64% 470.94% at 50% 52.32%,
    #398ab8 0%,
    #000000 100%
  );
  box-shadow: 0px 4px 100px 0px #004463 inset;
  margin-top: 100px;
  padding: 100px;

  p {
    font-family: Times New Roman;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
  }

  .hand-wave {
    img {
      width: 35px;
      height: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    .hand-wave {
      width: 30px;
      height: 20px;
    }
  }
`;

export const TermsContent = styled.div`
  h2 {
    font-family: Times New Roman;
    font-size: 35px;
    font-weight: 700;
    line-height: 40.25px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .title {
    margin: 100px;
  }

  .content-item {
    margin: 20px;
    p {
      text-align: left;
    }
    h4 {
      text-transform: uppercase;
      font-family: Times New Roman;
      font-size: 22px;
      font-weight: 400;
      line-height: 26.8px;
      letter-spacing: 0.1em;
      text-align: left;
    }
    ol {
      list-style-type: lower-roman;
    }
  
  }

 
  li {
    &::marker {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 768px) {
    .title {
      margin: 50px;
    }
  }
`;
