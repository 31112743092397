import React from "react";
import { AboutContainer, AboutContent } from "./about.styles";
import { Container, Row, Col } from "react-bootstrap";
import WaitList from "../../components/wait-list/wait-list.component";

const About = () => {
  return (
    <AboutContainer className="about laddr-background">
      <AboutContent>
        <Container>
          <Row>
            <Col>
              <div className="intro">
                <h1>About Us</h1>
                <p>
                  <span className="laddr-span">LADDR</span> is an Edtech startup
                  that offers a structured and engaging approach to learning
                  chess from beginner level to the master level.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <div className="value">
                <h1>Our Value Proposition</h1>
                <p>
                  Chess players around the world need access to a structured
                  system of chess education to facilitate effective learning. In
                  addition, the costs of coaching, courses and the stress of
                  acquiring information have been worrisome.
                </p>
                <p>
                  <span className="laddr-span">LADDR</span> will be providing a
                  seamless learning experience and would leverage its unique
                  curriculum & lessons to engage users on its platform for free!
                  <span className="laddr-span"> LADDR</span> also provides
                  exciting, engaging and fun filled learning experience to its
                  users, to facilitate learning. Embrace the future of chess
                  education with <span className="laddr-span">LADDR</span> and
                  improve your skills effortlessly!
                </p>
                <p>
                  <strong>
                    Get ready to climb the{" "}
                    <span className="laddr-span">LADDR</span> to chess mastery!
                  </strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </AboutContent>

    </AboutContainer>
  );
};

export default About;
