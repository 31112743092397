import styled from "styled-components";

export const CountdownSection = styled.div`
  text-align: center;
  position: relative;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;
    border: 8px solid transparent;
    border-image-source: radial-gradient(
      113.69% 1290.78% at 0% 50.28%,
      #fb088c 0%,
      #04e7d9 90.5%,
      #b83fee 97.78%,
      #8f00ff 99.99%,
      #666666 100%,
      #666666 100%
    );
    border-image-slice: 1;
    border-radius: 20px;
    width: auto;
    height: 150px;
    padding: 41px 49px;
    gap: 10px;

    box-shadow: 10px 10px 30px 0px #323333;
  }

  .item {
    padding: 10px;
    margin: 0 10px;
    p {
      font-family: Rubik;
      font-size: 30px;
      font-weight: 700;
      line-height: 37.4px;
      letter-spacing: 0.02em;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .countdown-item {
    /* Vector 31 */

    width: 0px;
    height: 105.5px;

    border: 1.5px solid #fff;
    transform: rotate(-0.2deg);
  }

  .alternative{
    display: none;
  }

  /* Mobile styles */
  @media screen and (max-width: 768px) {
    width: auto;

    .container {
      border: none;
      box-shadow: none;
      width: auto;
      gap: 0;
      padding: 0;
      justify-content: center;
    }

    .countdown-item {
      display: none;
    }

    .item {
      flex: 1;
      max-width: 50px;
      padding: 0;
      margin: 0;
      p {
        font-size: 25px;
      }
    }

    .start{
      display: none;
    }

    .alternative{
      display: flex;
    }
  }
`;
