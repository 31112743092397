import React from "react";
import {
  Announcement,
  FAQ,
  Hero,
  HomeContainer,
  LearnEasySmartly,
  SignInSignUp,
} from "./home.styles";
import Countdown from "../../components/countdown/countdown.component";
import { Col, Container, Row } from "react-bootstrap";
import ToLaunch from "../../assets/images/home/to-launch-white.png";
import WaitList from "../../components/wait-list/wait-list.component";
import faq from "../../faq";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <HomeContainer className="home-container laddr-background">
        <Hero className="hero">
          <Announcement>
            <div className="elevate">
              <Container>
                <Row>
                  <Col>
                    <p>Elevate your Chess Skills Effectively!</p>
                  </Col>
                </Row>
              </Container>
            </div>

          </Announcement>
        </Hero>

        <LearnEasySmartly className="learn-easily-smartly">
          <Container>
            <Row>
              <Col>
                <div className="learn-title">
                  <strong>Learn Easily.</strong>
                  <strong>Learn Smartly.</strong>
                </div>
                <p>
                  LADDR offers a captivating and interactive learning platform
                  with a distinctive curriculum, engaging users for free. It
                  promises a fun and effective way to enhance chess skills,
                  embracing the future of chess education effortlessly.
                </p>
              </Col>
            </Row>
          </Container>
        </LearnEasySmartly>

        <FAQ className="faq" id="faq">
          <div className="faq-title">
            <h3>FAQs</h3>
          </div>
          <div className="faq-list">
            {faq.map((item, index) => (
              <div className="faq-item" key={index}>

                <div className="faq-question">
                  <p>
                    <strong>Q: {item.Q}</strong></p>
                </div>
                <div className="faq-answer">
                  <p>A: {item.A}</p>
                </div>
              </div>
            ))}
          </div>
        </FAQ>

        <SignInSignUp className="sign-in-sign-up">
          <div className='early-access'>
            <p>
              Sign up now for early access and be among the first to embark on your journey to chess mastery with <span className="laddr-span"> LADDR</span>.
            </p>
          </div>
          <div className="buttons">
            <button style={{
              backgroundColor: "#000",
              color: "#fff",
              border: "1px solid #000",
              boxShadow: '0px 4px 4px 0px #00000066',
              borderRadius: '10px',
              padding: '10px',
              fontSize: '18px',
            }} 
            onClick={() => navigate("/register")}
            >Sign Up</button>
            <button style={{
              backgroundColor: "#fff",
              color: "#000",
              border: "1px solid #000",
              boxShadow: '0px 4px 4px 0px #00000066',
              borderRadius: '10px',
              padding: '10px',
              fontSize: '18px',
            }}
            onClick={() => navigate("/login")}
            >Log In</button>
          </div>
        </SignInSignUp>
      </HomeContainer>
    </>
  );
};

export default Home;
