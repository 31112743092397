import React, { useState, useEffect } from "react";
import {
  LaddrLogo,
  RegisterContainer,
  RegisterForm,
  RegisterSection,
  SignIn,
  SignUpButton,
} from "./register.styles";
import Countries from "../../countries";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LaddrIcon from "../../assets/images/main-app/laddr-icon.png";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import {
  usePostUsersMutation,
  useSendGoogleTokenMutation,
} from "../../store/apiSlice";
import { authTokenActions } from "../../store/authTokenSlice";
import GoogleIcon from "../../assets/icons/google-icon.png";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../utils/firebase.utils";
import LogoShow from "../../components/logo-show/logo-show.component";

const Register = () => {
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signUp, { isLoading }] = usePostUsersMutation();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validateUsername = (username) => {
    return username.trim() !== "";
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.trim().length >= 8 && !/\s/.test(password);
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must be at least 8 characters long. No spaces in password"
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (!validateConfirmPassword(password, newConfirmPassword)) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (!validateUsername(username)) {
      setUsernameError("Username is required");
      valid = false;
    } else {
      setUsernameError("");
    }

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 8 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!validateConfirmPassword(password, confirmPassword)) {
      setConfirmPasswordError("Passwords do not match");
      valid = false;
    } else {
      setConfirmPasswordError("");
    }

    if (valid) {
      try {
        const response = await signUp({
          email,
          password,
        }).unwrap();
        setSuccessMessage(response.message);
        navigate("/verify-email");
      } catch (error) {
        if (error.status === 500) {
          setSuccessMessage("An error occurred. Please try again later.");
        } else if (error.status === 400) {
          setErrorMessage(error.data.error.email);
        } else {
          setErrorMessage(
            "Signup failed. Please check your details and try again."
          );
        }
      }
    }
  };

  const [signingGoogle, setSigningGoogle] = useState(false);
  const [sendGoogleToken] = useSendGoogleTokenMutation();

  const signUpWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const token = result.user.accessToken;
      const email = result.user.email;

      await waitForTokenResponse({ token, email });
      dispatch(authTokenActions.addToken(token));
      navigate("/climb");
    } catch (error) {
      console.error("Error during Google sign-in:", error);
    } finally {
      setSigningGoogle(false);
    }
  };

  const waitForTokenResponse = async ({ token, email }) => {
    setSigningGoogle(true);
    try {
      await sendGoogleToken({ token: token, email: email }).unwrap();
      dispatch(authTokenActions.addToken(token));
      navigate("/climb");
    } catch (err) {
      console.error("An error occured sending token: ", err);
    }
  };

  return (
    <RegisterSection className="register laddr-background">
      <Container>
        <RegisterContainer>
          {signingGoogle && <LogoShow />}
          <LaddrLogo className="laddr-logo">
            <img src={LaddrIcon} alt="Laddr" />
          </LaddrLogo>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h1>Sign Up</h1>
            <span>It's quick and easy</span>
          </div>
          <br />
          <RegisterForm onSubmit={handleSubmit}>
            <div className="username">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter Your Username"
              />
              {usernameError && <p className="error">{usernameError}</p>}
            </div>
            <div className="email">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email"
              />
              {emailError && <p className="error">{emailError}</p>}
            </div>
            <div className="password">
              <div style={{ position: "relative", width: "fit-content" }}>
                <input
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter your password"
                  style={{ paddingRight: "40px" }}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={passwordVisible ? faEyeSlash : faEye}
                  />
                </span>
              </div>
              {passwordError && <p className="error">{passwordError}. </p>}
            </div>
            <div className="confirm-password">
              <div style={{ position: "relative", width: "fit-content" }}>
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Enter your password"
                  style={{ paddingRight: "40px" }}
                />
                <span
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={confirmPasswordVisible ? faEyeSlash : faEye}
                  />
                </span>
              </div>
              {confirmPasswordError && (
                <p className="error">{confirmPasswordError}</p>
              )}
            </div>
            {/* <div className="options">
            <Container>
              <Row>
                <Col>
                  <SearchableDropdown
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Col>
                <Col>
                  <div className="gender">
                    <select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                  </div>
                </Col>
              </Row>
            </Container>
          </div> */}
            {successMessage && <p className="success">{successMessage}</p>}
            {errorMessage && (
              <p className="error">
                {errorMessage} <br /> Resend Verification Email?{" "}
                <Link to="/resend-email">Click Here</Link>
              </p>
            )}
            <SignUpButton type="submit">
              {isLoading ? "Loading..." : "Sign Up"}
            </SignUpButton>
            <button
              className="signup-with-google"
              type="button"
              disabled={signingGoogle}
              onClick={signUpWithGoogle}
            >
              <img src={GoogleIcon} alt="Google Icon" />
              Signup With Google
            </button>
          </RegisterForm>
          <SignIn>
            <p>
              Already signed up? <Link to="/login">Sign in </Link>
            </p>
          </SignIn>
        </RegisterContainer>
      </Container>
    </RegisterSection>
  );
};

const SearchableDropdown = ({ value, onChange }) => {
  const [search, setSearch] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Countries);

  useEffect(() => {
    setFilteredCountries(
      Countries.filter((country) =>
        country.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);

  return (
    <div className="searchable-dropdown">
      <select value={value} onChange={onChange}>
        <option value="">Select Country</option>
        {filteredCountries.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Register;
