import React from "react";
import {
  Accessibility,
  FeaturesContainer,
  FeaturesItem,
} from "./features.styles";
import PuzzlesImage from "../../assets/images/features/puzzles.png";
import ClimbImage from "../../assets/images/features/climb.png";
import CurriculumImage from "../../assets/images/features/curriculum.png";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const Features = () => {
  return (
    <>
      <FeaturesContainer className="laddr-background">
        <Container>
          <Row>
            <Col>
              <h1>Features</h1>
              <div className="features-list">
                <FeaturesItem
                  className="accessibility"
                  style={{ background: "#00367e", color: "#fff" }}
                >
                  <Container>
                    <Row>
                      <Col>
                        <h2>Accessibility</h2>
                        <p>
                          You can access{" "}
                          <span className="laddr-span">LADDR</span> easily by
                          going to the website from anywhere in the world.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </FeaturesItem>
                <FeaturesItem
                  className="climb"
                  style={{
                    background: "transparent",
                    marginBottom: "50px",
                    background:
                      "radial-gradient(323.03% 2394.01% at 50% 50%, #0666E6 0%, #051A1A 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    textAlign: "left",
                    color: "transparent",
                  }}
                >
                  <Container>
                    <Row>
                      <h2 style={{ textAlign: "left" }}>Climb</h2>
                      <Col>
                        <div className="content">
                          <p>
                            <span className="laddr-span">LADDR's</span> climb
                            feature is a product that entails 3 program levels,
                            Beginner (0-1200), Amateur (1200-1600), and Club
                            player (1600-2000). We prioritize these groups of
                            learners, hence our unique curriculum is structured
                            to make you climb from beginner to master!
                          </p>
                        </div>
                      </Col>

                      <Col>
                        <img src={ClimbImage} alt="climb-image" />
                      </Col>
                    </Row>
                  </Container>
                </FeaturesItem>
                <FeaturesItem
                  className="curriculum"
                  style={{ background: "#00367e", color: "#fff" }}
                >
                  <Container>
                    <Row>
                      <h2>Unique Curriculum</h2>
                      <Col>
                        <div className="content">
                          <p>
                            Have you heard of the elements of chess?{" "}
                            <span className="laddr-span">LADDR's</span>{" "}
                            unique curriculum was derived from it. These are key
                            components of chess that chess players all over the
                            world, from the beginner next door to the world
                            champion on the global stage are constantly refining
                            to improve their knowledge, skills, and abilities.
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <img src={CurriculumImage} alt="curriculum-image" />
                      </Col>
                    </Row>
                  </Container>
                </FeaturesItem>
                <FeaturesItem
                  className="experience"
                  style={{
                    background: "transparent",
                    marginBottom: "50px",
                    background:
                      "radial-gradient(323.03% 2394.01% at 50% 50%, #0666E6 0%, #051A1A 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    textAlign: "center",
                    color: "transparent",
                  }}
                >
                  <Container>
                    <Row>
                      <Col>
                        <h2>Gamified Experience</h2>
                        <p>
                          Learning should be fun and engaging! <span className="laddr-span">LADDR</span> would
                          utilize rewards, challenges, leaderboards, interactive
                          sounds, and animations to engage users.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </FeaturesItem>
                <FeaturesItem
                  className="puzzles"
                  style={{ background: "#00367e", color: "#fff" }}
                >
                  <h2>Puzzles</h2>
                  <Container>
                    <Row>
                      <Col>
                        <div className="content">
                          <p>
                            Our puzzles are also specially collated to suit
                            interests across several topics.
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <img src={PuzzlesImage} alt="puzzles-image" />
                      </Col>
                    </Row>
                  </Container>
                </FeaturesItem>
              </div>
            </Col>
          </Row>
        </Container>
      </FeaturesContainer>
      <Outlet />
    </>
  );
};

export default Features;
