const faq = [
  {
    Q: "What is LADDR?",
    A: "LADDR is an Edtech platform that teaches people chess from beginner to master level.",
  },
  {
    Q: "What sets LADDR apart from other chess EDtech & traditional learning systems?",
    A: `LADDR’s main focus is across the beginner-club player level (0-2000). \ 
        It leverages technology to facilitate learning for its users, using a uniquely structured curriculum system that was developed from the elements of chess to provide users with engaging and interactive lessons across its program levels.`,
  },
  {
    Q: "Can I really learn chess from scratch using LADDR even if I’ve never played before?",
    A: "Yes you can! You are so important to us and we have specially designed a unique system to provide you with effective guidance and instruction to learn about the game of chess.",
  },
  {
    Q: "Is LADDR suitable for children and young learners?",
    A: "Yes it is! LADDR is suitable for learners of all ages. The lessons are engaging and fit for all learners. We utilize gamification to make learning fun and effective.",
  },
  {
    Q: "Would LADDR be really free to use?",
    A: "Yes it would! We utilize a freemium and premium model to offer users our products and services at no cost.",
  },
  {
    Q: "When would LADDR launch?",
    A: "LADDR is scheduled to launch beta on the 18th of June 2024. Ensure to sign up for the waitlist to get notified when we launch",
  },
];

export default faq;
