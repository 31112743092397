import React from "react";
import {
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./routes/home/home.route";
import Navigation from "./components/navigation/navigation.component";
import Footer from "./components/footer/footer.component";
import About from "./routes/about/about.route";
import "./App.css";
import NoPage from "./routes/no-page/no-page.route";
import Features from "./routes/features/features.route";
import Terms from "./routes/terms/terms.route";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top.component";
import ScrollToTopButton from "./components/scroll-to-top/scroll-to-top-button.component";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy.route";
import Contact from "./routes/contact/contact.route";
import Login from "./routes/login/login.route";
import Register from "./routes/register/register.route";
import Dashboard from "./routes/dashboard/dashboard.route";
import { useSelector, useDispatch } from "react-redux";
import Layout from "./scences/Layout";
import Climb from "./routes/climb/climb.route";
import { useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Leader from "./routes/leader/leader.route";
import ClimbRoutes from "./routes/climb/climb.route";
import Beginner from "./routes/beginner/beginner.routes";
import { CssBaseline } from "@mui/material";
import VerifyEmail from "./routes/verify-email/verify-email.route";
import ForgotPassword from "./routes/forgot-password/forgot-password.component";
import ResendEmail from "./routes/resend-email/resend-email.component";
import ForgotPasswordPage from "./routes/forgot-password/forgot-password.component";
import User from "./scences/User/User";
import { ProtectedRoutes } from "./routes/protected/protected.route";
import { RootLayout } from "./components/root-layout/root-layout.component";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const user = useSelector((state) => state.user.user);
  
  return (
    <>
      <ScrollToTop />
      {/* <ScrollToTopButton /> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="main">
          <Routes>
            <Route element={<ProtectedRoutes />}>
              {/* <Route path="/" element={user ? <Navigate to="/climb" replace /> : <Home />} /> */}
              {/* <Route path="/" element={user && <Navigate to="/climb" replace />} /> */}
              <Route path="/climb/*" element={<ClimbRoutes />} />
              {/* lAYOUT ROUTES */}
              <Route path="puzzle" element={<Navigate to="/climb" replace />} />
              <Route path="shop" element={<Navigate to="/climb" replace />} />
              <Route
                path="leaderboard"
                element={<Navigate to="/climb" replace />}
              />
              <Route path="quest" element={<Navigate to="/climb" replace />} />
              <Route path="more" element={<User />} />
            </Route>
            <Route element={<RootLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="features" element={<Features />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="terms" element={<Terms />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="verify-email/*" element={<VerifyEmail />} />
              <Route path="resend-email" element={<ResendEmail />} />
              <Route path="password-reset/*" element={<ForgotPasswordPage />} />

              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
