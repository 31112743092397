import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Beginner from "../beginner/beginner.routes";
import PawnIcon from "../../assets/dashboard/pawn-icon.png";
import KnightIcon from "../../assets/dashboard/knight-icon.png";
import KingIcon from "../../assets/dashboard/king-icon.png";
import InfoIcon from "../../assets/dashboard/info-icon.png";
import ProgressBar from "../../components/progress-bar/progress-bar.component";
import Units from "../units/units.component";

const Climb = () => {
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const onClickHandler = async (e) => {
    e.preventDefault()
    navigate("/climb/beginner");


  }


  return (
    <Box m={isNonMobile ? "1.5rem 2.5rem 0 2.5rem" : "1.5rem 1rem 0 1rem"} p="0 0 3.5rem 0">
      <Box
        mt="20px"
        display="flex"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Card
          sx={{
            backgroundImage: "none",
            background:
              "radial-gradient(99.13% 222.22% at 50% 39.88%, #4BAB33 0%, #004040 100%)",
            borderRadius: "0.55rem",
            width: "100%",
          }}
        >
          <CardContent>
            <Box
              sx={{
                textAlign: "left",
                display: "flex",
                gap: "1rem",
                justifyContent: "left",
              }}
            >
              <img
                style={{ width: "3.5rem", height: "3.5rem" }}
                src={PawnIcon}
                alt=""
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <Typography
                      sx={{
                        borderRadius: "10px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
                        padding: isNonMobile ? "1.5rem" : "1rem",
                        border: "1px solid #00367E9E",
                        fontFamily: "Rubik",
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "20.7px",
                        textAlign: "left",
                        marginRight: "20px",
                        marginBottom: "20px",
                      }}
                      backgroundColor={theme.palette.background.alt1}
                      color={theme.palette.primary[100]}
                      width="auto"
                      gutterBottom
                    >
                      Beginner 1200
                    </Typography>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <Typography
                      sx={{
                        borderRadius: "30%",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
                        padding: isNonMobile ? "1.5rem" : "1rem",
                        border: "1px solid #00367E9E",
                        fontFamily: "Rubik",
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "20.7px",
                        textAlign: "left",
                        verticalAlign: "middle",
                        float: isSmallScreen ? 'left' : 'right',
                        backgroundColor: theme.palette.background.alt1,
                      }}
                      onClick={() => navigate("/climb/beginner")}
                    >
                      {/* <Button onClick={onClickHandler}>Start</Button> */}
                      <Link to="/climb/beginner" style={{ color: theme.palette.primary[100] }} >Start</Link>
                    </Typography></Col>
                </Row></Container>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ width: "3.5rem", height: "3.5rem" }}
                src={InfoIcon}
                alt=""
              />
              {/* <Box>
              <ProgressBar value={50} maxValue={100} />
            </Box> */}
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box
        mt="20px"
        display="flex"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Card
          sx={{
            backgroundImage: "none",
            background:
              "radial-gradient(158.53% 337.92% at 51.59% 50%, #DF539F 0%, #1C033C 95.96%)",
            borderRadius: "0.55rem",
            width: "100%",
          }}
        >
          <CardContent>
            <Box
              sx={{
                textAlign: "left",
                display: "flex",
                gap: "1rem",
                justifyContent: "left",
              }}
            >
              <img
                style={{ width: "3.5rem", height: "3.5rem" }}
                src={KnightIcon}
                alt=""
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <Typography
                      sx={{
                        borderRadius: "20px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
                        padding: isNonMobile ? "1.5rem" : "1rem",
                        border: "1px solid #00367E9E",
                        fontFamily: "Rubik",
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "20.7px",
                        textAlign: "left",
                        marginRight: "20px",
                        marginBottom: "20px",
                        background: "#df539f99",
                      }}
                      color={theme.palette.primary[100]}
                      width="auto"
                      gutterBottom
                    >
                      Amateur 1200 - 1600
                    </Typography></Col>

                  <Col>
                    <Typography
                      sx={{
                        borderRadius: "30%",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
                        padding: isNonMobile ? "1.5rem" : "0.5rem",
                        border: "1px solid #00367E9E",
                        fontFamily: "Rubik",
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "20.7px",
                        textAlign: "left",
                        verticalAlign: "middle",
                        float: isSmallScreen ? 'left' : 'right',
                        backgroundColor: 'inherit'
                      }}
                    >

                      <Button disabled sx={{ textTransform: "none" }}>Coming soon...</Button>
                    </Typography></Col>
                </Row></Container>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ width: "3.5rem", height: "3.5rem" }}
                src={InfoIcon}
                alt=""
              />
              {/* <Box>
              <ProgressBar value={50} maxValue={100} />
            </Box> */}
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box
        mt="20px"
        display="flex"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Card
          sx={{
            backgroundImage: "none",
            background:
              "radial-gradient(161.59% 161.59% at 50% 50%, #398AB8 0%, #398AB8 0.01%, #051A1A 100%)",
            borderRadius: "0.55rem",
            width: "100%",
          }}
        >
          <CardContent>
            <Box
              sx={{
                textAlign: "left",
                display: "flex",
                gap: "1rem",
                justifyContent: "left",
              }}
            >
              <img
                style={{ width: "3.5rem", height: "3.5rem" }}
                src={KingIcon}
                alt=""
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 0",
              }}
            >
              <Container>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <Typography
                      sx={{
                        borderRadius: "20px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
                        padding: isNonMobile ? "1.5rem" : "1rem",
                        border: "1px solid #00367E9E",
                        fontFamily: "Rubik",
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "20.7px",
                        textAlign: "left",
                        marginRight: "20px",
                        marginBottom: "20px",
                        background: "#398ab8",

                      }}
                      color={theme.palette.primary[100]}
                      width="auto"
                      gutterBottom
                    >
                      Club Player 1600 - 2000
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      sx={{
                        borderRadius: "30%",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
                        padding: isNonMobile ? "1.5rem" : "0.5rem",
                        border: "1px solid #00367E9E",
                        fontFamily: "Rubik",
                        fontSize: "15px",
                        fontWeight: 600,
                        lineHeight: "20.7px",
                        textAlign: "left",
                        verticalAlign: "middle",
                        float: isSmallScreen ? 'left' : 'right',
                        backgroundColor: "inherit"
                      }}
                    >
                      <Button disabled sx={{ textTransform: "none" }}>Coming soon...</Button>
                    </Typography>
                  </Col>
                </Row></Container>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ width: "3.5rem", height: "3.5rem" }}
                src={InfoIcon}
                alt=""
              />
              {/* <Box>
              <ProgressBar value={50} maxValue={100} />
            </Box> */}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box >
  );
};

const ClimbRoutes = () => {
  return (
    <>
      <Routes>
        <Route index element={<Climb />} />
        <Route path="/beginner/*" element={<Beginner />} />
      </Routes>
    </>
  );
};

export default ClimbRoutes;
