import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WaitListContainer } from "./wait-list.styles";
import { setEmail, setMessage, submitEmail } from "../../store/email/emailSlice";

// Email validation function
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const WaitList = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.email.email);
  const status = useSelector((state) => state.email.status);
  const message = useSelector((state) => state.email.message);
  const submittedEmails = useSelector((state) => state.email.submittedEmails);
  const [error, setError] = useState("");

  useEffect(() => {
    let messageTimer;
    if (message) {
      messageTimer = setTimeout(() => {
        dispatch(setMessage('')); // assuming you have a setMessage action
      }, 5000); // Message will disappear after 5 seconds
    }
    return () => clearTimeout(messageTimer);
  }, [message, dispatch]);

  useEffect(() => {
    let errorTimer;
    if (error) {
      errorTimer = setTimeout(() => {
        setError('');
      }, 5000); // Error will disappear after 5 seconds
    }
    return () => clearTimeout(errorTimer);
  }, [error]);

  const handleChange = (event) => {
    dispatch(setEmail(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");

    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (submittedEmails.includes(email)) {
      setError("This email has already been submitted.");
      return;
    }
    dispatch(submitEmail(email));
  };

  return (
    <WaitListContainer className="waitlist">
      <p className="join">Join Our Waitlist Today!</p>
      <div className="notified">
        <p>Get notified when we’re launching.</p>
      </div>
      <div className="sign-up">
        <p>
          Sign up now for early access and be among the first to embark on your
          journey to chess mastery with{" "}
          <span className="laddr-span">LADDR</span>
        </p>
        <div className="notify">
          <form onSubmit={handleSubmit}>
            <input
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={handleChange}
              aria-describedby="emailHelp"
            />
            <button type="submit" disabled={status === "loading"}>
              Notify me
            </button>
          </form>
          <br />
        </div>

        <div className="message">
          {error && <p style={{ color: "red" }}>{error}</p>}
          {message && <p>{message}</p>}
        </div>
      </div>
    </WaitListContainer>
  );
};

export default WaitList;
