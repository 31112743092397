import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import StarComponent from "../../components/star/star.component";
import Lesson from "../lesson/lesson.route";
import { useGetLessonsByUnitIdQuery } from "../../store/apiSlice";
import { setSelectedUnit } from "../../store/unitsSlice";
import { setSelectedLessonSerial } from "../../store/lessonSlice";

const UnitsPage = () => {
  const dispatch = useDispatch();
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [selectedLessonSerialumber, setSelectedLessonSerialNumber] = useState(null);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();
  const theme = useTheme();
  const selectedSection  = useSelector((state) => state.sections.selectedSection);
  
  

  const units = useSelector((state) => state.units.units.data);

  const onStarClickHandler = (unitSerial, starNumber, unitId) => {
    navigate(
      `/climb/beginner/section/${selectedSection}/units/${unitSerial}/lessons/${starNumber}`
    );
    dispatch(setSelectedLessonSerial(starNumber))
    
  };
   
  const onClickHandler = (e, unitId) => {
    e.preventDefault();
    setSelectedUnitId(unitId);
    dispatch(setSelectedUnit(unitId))
  };

  return (
    <Box paddingBottom="2.5rem">
      {units?.map((unit) => (
        <Box
          key={unit.serial}
          mt="20px"
          p="1.5rem 3.5rem"
          display="block"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": {
              gridColumn: isNonMobile ? undefined : "span 4",
            },
          }}
        >
          <Card
            sx={{
              backgroundImage: "none",
              background:
                "radial-gradient(99.13% 222.22% at 50% 39.88%, #4BAB33 0%, #004040 100%)",
              borderRadius: "0.55rem",
              width: "100%",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  fontFamily: "Rubik",
                  fontSize: "25px",
                  fontWeight: 600,
                  lineHeight: "30.7px",
                  textAlign: "left",
                  color: "#fff",
                  borderBottom: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    padding: "0",
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "23.7px",
                    textAlign: "left",
                  }}
                  color={theme.palette.primary[100]}
                  width="auto"
                  gutterBottom
                >
                  Unit {unit.serial}
                </Typography>
                <Typography>{unit.title}</Typography>
              </Box>
              <Box display="flex" m="20px 0" sx={{ gap: "1rem" }}>
                <Button onClick={(e) => onClickHandler(e, unit.id)}>
                  <StarComponent
                    number={unit.no_of_lessons}
                    onStarClick={(starNumber) =>
                      onStarClickHandler(unit.serial, starNumber, unit.id)
                    }
                  />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  );
};

const Units = () => {
  return (
    <Routes>
      <Route index element={<UnitsPage />} />
      <Route path="/:serial/lessons/*" element={<Lesson />} />
    </Routes>
  );
};

export default Units;
