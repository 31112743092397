import React from "react";
import { TermsContainer, TermsContent, TermsHero } from "./terms.styles";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Terms from "../../terms";
import HandWave from '../../assets/icons/hand-wave.png';

const TermsPage = () => {
  return (
    <>
      <TermsContainer>
        <TermsHero>
          <Container>
            <Row>
              <Col>  
                <p>Hello <i className="hand-wave"><img src={HandWave} alt="" /></i></p>
                <br />
                <p>
                  Before you create an account, kindly read and accept our Terms
                  & Conditions.
                </p>
              </Col>
            </Row>
          </Container>
        </TermsHero>
        <TermsContent>
          <Container>
            <Row>
              <Col>
                <div className="title">
                  <h2>LADDRCHESS LTD TERMS & CONDITIONS</h2>
                </div>
                <div className="content">
                  <ol>
                    <li>
                      <div className="content-item">
                        <h4>Introduction</h4>
                        <p>
                          Welcome to <span className="laddr-span">Laddrchess Ltd</span> ("Laddrchess," "we," "us,"
                          or "our"). These Terms of Service govern your use of
                          the Laddrchess website, products and services.
                        </p>
                        <br />
                        <p>
                          You agree to comply with these terms by accessing or
                          using our services.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Use of Service</h4>
                        <p>{Terms.useOfService}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>User Accounts</h4>
                        <p>{Terms.userAccounts}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Payments</h4>
                        <p>{Terms.payments}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Intellectual Property</h4>
                        <p>{Terms.intellectualProperty}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Prohibitted Users</h4>
                        <div>
                          <p>{Terms.prohibitedUsers.title}</p>
                          <ol className="roman-numerals">
                            {Terms.prohibitedUsers.list.map((item, index) => (
                              <li key={index}><p>{item}</p></li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>
                          Disclaimer of warranties; Limitation of Liability
                        </h4>
                        <p>{Terms.disclaimer}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Indemnification</h4>
                        <p>{Terms.indemnification}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Termination</h4>
                        {Terms.termination.map((item, index) => (
                          <p key={index}>{item}</p>
                        ))}
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Entire Agreement</h4>
                        {Terms.agreement.map((item, index) => (
                          <p key={index}>{item}</p>
                        ))}
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Governing Law</h4>
                        <p>{Terms.law}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Changes to Terms of Service</h4>
                        <p>{Terms.changes}</p>
                      </div>
                    </li>
                    <li>
                      <div className="content-item">
                        <h4>Contact Us</h4>
                        <p>{Terms.contact}</p>
                      </div>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
          </Container>
        </TermsContent>
      </TermsContainer>
    </>
  );
};

export default TermsPage;
