import { createSlice } from "@reduxjs/toolkit"
import { apiSlice } from "./apiSlice"

export const UnitsSlice = createSlice({
    name: "units",
    initialState: {
        units: null,
        selectedUnit: null
    },
    reducers: {
        setUnits: (state, action) => {
            state.units = action.payload
        },
        setSelectedUnit: (state, action) => {
            state.selectedUnit = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            apiSlice.endpoints.getUnitsBySectionId.matchFulfilled,
            (state, action) => {
                state.units = action.payload
            }
        )
    }
});

export const { setUnits, setSelectedUnit } = UnitsSlice.actions

export default UnitsSlice.reducer