import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

export const sectionSlice = createSlice({
    name: "section",
    initialState: {
        section: null,
        selectedSection: null
    },
    reducers: {
        setSection: (state, action) => {
            state.section = action.payload
        },
        setSelectedSection: (state, action) => {
            state.selectedSection = action.payload
        }
    },

    extraReducers: (builder) => {   
        builder
        .addMatcher(
            apiSlice.endpoints.getSections.matchFulfilled,
            (state, action) => {
                state.section = action.payload
            }
        )
    }
})

export const { setSection, setSelectedSection } = sectionSlice.actions

export default sectionSlice.reducer;