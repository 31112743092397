import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useRefreshTokenMutation,
  useSendGoogleTokenMutation,
} from "../../store/apiSlice";
import { straightLogin } from "../../store/user/userSlice";
import {
  LaddrLogo,
  LoginButtons,
  LoginContainer,
  LoginForm,
  LoginSection,
  SignUp,
} from "./login.styles";
import LaddrIcon from "../../assets/images/main-app/laddr-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import GoogleIcon from "../../assets/icons/google-icon.png";
import LogoShow from "../../components/logo-show/logo-show.component";
import { useLocalStorage } from "../../hooks/useLocalstorage";
import { useDispatch } from "react-redux";
import { authTokenActions } from "../../store/authTokenSlice";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../utils/firebase.utils";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const [verifyEmailDisplay, setVerifyEmailDisplay] = useState(false);
  const [refreshTokenSender] = useRefreshTokenMutation();

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validatePassword = (password) => password.length >= 8;

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError("Password must be at least 8 characters long");
    } else {
      setPasswordError("");
    }
  };

  const [signingGoogle, setSigningGoogle] = useState(false);
  const [sendGoogleToken] = useSendGoogleTokenMutation();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
    
      const token = result.user.accessToken;
      const email = result.user.email;

      await waitForTokenResponse({ token, email });
    } catch (error) {
      console.error("Error during Google sign-in:", error);
    } finally {
      setSigningGoogle(false);
    }
  };

  const waitForTokenResponse = async ({ token, email }) => {
    setSigningGoogle(true);
    try {
      await sendGoogleToken({ token: token, email: email }).unwrap();
      dispatch(authTokenActions.addToken(token));
      navigate("/climb");
    } catch (err) {
      console.error("An error occured sending token: ", err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else if (!validatePassword(password)) {
      setPasswordError("Password must be at least 8 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      try {
        const response = await login({ email, password }).unwrap();
        const { access_token } = response?.token;

        dispatch(authTokenActions.addToken(access_token));
        navigate("/climb");
      } catch (error) {
        if (error.status === 401) {
          setPasswordError(error.data.error);
        }

        if (error.data.error === "Account not verified") {
          setVerifyEmailDisplay(true);
          setShowResend(true);
        }
      }
    }
  };

  return (
    <LoginSection className="login-section laddr-background">
      <Container>
        {signingGoogle && <LogoShow />}
        {!isLoading ? (
          <LoginContainer className="login-container">
            <LaddrLogo className="laddr-logo">
              <img src={LaddrIcon} alt="Laddr" />
            </LaddrLogo>
            <LoginForm onSubmit={handleSubmit}>
              <div className="login-info">
                <div className="email">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {emailError && <p className="error">{emailError}</p>}
                </div>
                <div className="password">
                  <div style={{ position: "relative", width: "fit-content" }}>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter your password"
                      style={{ paddingRight: "40px" }}
                      required
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={passwordVisible ? faEyeSlash : faEye}
                      />
                    </span>
                  </div>
                </div>
                <div className="forgot">
                  <Link to="/password-reset">Forgot Password?</Link>
                </div>
              </div>
              {passwordError && (
                <p className="error">
                  {passwordError}.{" "}
                  {showResend && (
                    <Link to="/resend-email">Resend verification email</Link>
                  )}{" "}
                </p>
              )}
              <LoginButtons className="login-buttons">
                <button type="submit" className="login" disabled={isLoading}>
                  {isLoading ? "Logging in..." : "Login"}
                </button>
                <p>or</p>
                <button
                  className="login-with-google"
                  type="button"
                  disabled={signingGoogle}
                  onClick={signInWithGoogle}
                >
                  <img src={GoogleIcon} alt="Google Icon" />
                  Login With Google
                </button>
              </LoginButtons>
            </LoginForm>
            <SignUp>
              <div className="line" />
              <div>
                <p>
                  Don’t have an account?{" "}
                  <Link to="/register">Sign up here</Link>
                </p>
              </div>
            </SignUp>
          </LoginContainer>
        ) : (
          <LogoShow />
        )}
      </Container>
    </LoginSection>
  );
};

export default Login;
