import styled from "styled-components";

export const FeaturesContainer = styled.section`
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 200px;

  h1{
    margin-bottom: 50px;
    background: radial-gradient(323.03% 2394.01% at 50% 50%, #0666E6 0%, #051A1A 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    color: transparent;

    @media (max-width: 768px) { 
      margin-bottom: 30px;
    }
  }

  .features-list{
    margin-top: 100px;

    @media (max-width: 768px) { 
      margin-top: 50px;
    }
  }
`;

export const FeaturesItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px;
  border-radius: 30px;
  border: 10px;
  opacity: 0px;
  border: 3px solid #000;
  box-shadow: 0px 5px 4px 0px #0000001a inset;
  text-align: center;
  margin-bottom: 100px;

  h2 {
    padding: 30px;
  }

  img {
    object-fit: cover;
    margin: 0 auto;
    max-width: 400px;
    max-height: 400px;
  }

  .content {
    display: flex;
    p {
      text-align: left;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;

    h2 {
      padding-bottom: 20px;
      font-size: 32px;
    }

    p {
      font-size: 20px;
    }

    img {
      max-width: 250px;
      max-height: 250px;
    }
  }
`;
