import styled from "styled-components";
import HeroBG from "../../assets/images/home/hero-bg.png";

export const HomeContainer = styled.section`
  position: relative;
  height: 100%;
  padding-bottom: 100px;

  .hero {
    margin-top: -200px;
  }

  .launch {
    position: relative;
    color: #fff;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
      display: block;
      max-width: 200px;
      max-height: 200px;
    }
  }

  @media (max-width: 768px) {
    .hero {
      margin-top: 100px;
    }
  }
`;

export const Hero = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${HeroBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 130vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-top: 200px;

  @media (max-width: 768px) {
    height: 100vh;
    padding-top: 0;
  }
`;

export const LearnEasySmartly = styled.div`
  background-color: #f5f5f5;
  position: relative;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 4px 0px #004463;
  border-radius: 20px;
  padding: 10px;
  margin: 150px;
  text-align: center;
  height: 100%;

  .learn-title {
    display: flex;
    justify-content: center;
    gap: 50%;
    padding: 30px;
    font-size: 32px;
  }

  p {
    color: #000;
    padding: 30px;
    font-size: 25px;
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    color: #fff !important;
    background: black;

    p {
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    margin: 100px 20px;
    height: auto;
    p {
      padding: 0 !important;
      text-align: left;
    }

    .learn-title {
      font-size: 25px !important;
      gap: 30%;
    }
  }


`;

export const FAQ = styled.div`
  background: linear-gradient(177.58deg, #FFFFFF -77.29%, #00367E 34.47%);
  box-shadow: 0px 10px 5px 0px #0044634d inset;
  border-radius: 30px;
  color: #fff;
  margin: 100px;
  padding: 100px 20px;

  .faq-title {
    text-align: center;
    margin: 30px auto; 
    width: 200px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px #fffdfdb2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;

    h3 {
    
    color: #000 !important;
      padding: 10px;
      font-size: 32px;
      font-weight: 600;
      margin: 0; 
    }
  }

  .faq-item {
    background: #ffffff;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px 0px #fffdfdb2;
    border-radius: 10px;
    margin: 50px;
    height: auto;

    p {
      color: #000;
      text-align: left;
    }

    div {
      display: flex;
      align-items: center;
      padding: 20px;
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0.02em;
      gap: 10px;
      justify-content: left;
    }
  }

  @media (max-width: 768px) {
    margin: 10px;
    padding: 10px 20px;
    .faq-item {
      height: auto;

      min-height: 300px;
      margin: 30px 0;
    }
  }
`;

export const Announcement = styled.div`
  position: relative;

  .elevate {
    width: 700px;
    margin: 0 auto;
    position: relative; /* Ensure the element is positioned relative to its normal position */
    animation: fade-in 3s;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(300px); /* Use translateY for smooth vertical movement */
    }
    to {
      transform: translateY(0);
      opacity: 1; /* Use 1 for full opacity */
    }
  }


  p {
    font-family: Rubik;
    font-size: 66px;
    font-weight: 600;
    line-height: 70.03px;
    letter-spacing: 0.02em;
    text-align: center;
    background: linear-gradient(90deg, #336cb9 8.5%, #e4007b 76.5%);
    background-clip: text;
    color: transparent;
    -webkit-text-stroke-width: 1px; 
    -webkit-text-stroke-color: #fff;
    padding: 1rem;
    position: relative;
  }

  @media (max-width: 768px) {
    .elevate {
      width: auto;

      p {
        font-size: 30px !important;
        line-height: 35px !important;
      }
    }
  }
`;

export const SignInSignUp = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  height: auto;
  background: radial-gradient(535.03% 563.86% at 46.2% 0%, rgba(255, 251, 251, 0.9) 0%, rgba(254, 251, 251, 0.9) 8.41%, rgba(255, 255, 255, 0.9) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid #00367E;
  margin: 0 auto;
  padding: 30px;
  border-radius: 30px;
  margin: 50px;

  .early-access{
    width: 60%;    
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 20px auto; 
  }

  .buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 50px;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
    padding: 20px;

    .buttons{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      gap: 50px;


      button{
        font-size: 15px !important; 
        padding: 5px;FF      
      }
    }

    .early-access{
      width: 90%;
    }
  }

`;
