import styled from "styled-components";

export const ContactContainer = styled.section`
  margin: 100px 0 0 0;
  padding: 50px 0 100px 0;

  .address, .socials{
    padding-top: 50px;

    div{
      padding-bottom: 10px;
    }
  }

  h1 {
    padding: 50px 0 0;
    background: linear-gradient(90deg, #01152f 0%, #e4007b 100%);
    background-clip: text;
    color: transparent;
    text-align: center;
  }

  h2 {
    background: linear-gradient(90deg, #01152f 0%, #e4007b 100%);
    background-clip: text;
    color: transparent;
  }

  .address {
    i {
      padding-right: 10px;
      font-size: 25px;
      line-height: 37.4px;
    }

    .location {
      display: flex;
    }
  }

  .socials {
    ul {
      color: #000;
      display: block;
      list-style: none;
      line-height: 30px;
      padding-left: 0;
    }

    li {
      margin: 30px;
      height: 20px;
      color: #000;

      i {
        color: #000;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid #000;
        padding: 5px;
        font-size: 20px;
      }
    }

    a {
      text-decoration: none;
    }
  }
`;
