import styled from "styled-components";

export const FooterContainer = styled.footer`
  position: relative;
  height: 100%;
  padding: 50px;
  background: #000;

  a {
    font-family: Rubik;
    font-size: 20px;
    font-weight: 100;
    line-height: 35.55px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #c64c6c;
      transition: 0.5s;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const LogoContainer = styled.div`
  img {
    width: 150px;
    height: 200px;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const FooterList = styled.div`
  h3 {
    padding: 30px 10px 10px 10px;
    font-family: Rubik;
    font-size: 30px;
    font-weight: 500;
    line-height: 35.55px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    text-align: left;

    li {
      padding: 10px;
     
    }
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  gap: 20px;
  color: #fff;
  p {
    margin-right: 20px;
    font-family: Rubik;
    font-size: 20px;
    font-weight: 100;
    line-height: 28.44px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .copyright, .terms-and-privacy {
    display: flex;
  }

  .socials {
   ul{
    color: #ffffff;
    display: flex;
    list-style: none;
    line-height: 30px;
    float: right;
   }

    li {
      margin-right: 20px;
      height: 20px;
      color: #ffffff;

      i {
        color: #ffffff;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        padding: 5px;
        font-size: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

