import React, { useState } from "react";
import DashboardSidebar from "../components/dashboard-sidebar/dashboard-sidebar.component";
import DashboardHeader from "../components/dashboard-header/dashboard-header.component";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <>
      <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
        <DashboardSidebar
          isNonMobile={isNonMobile}
          drawerWidth="350px"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Box flexGrow={1}>
          <DashboardHeader
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />

          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
