import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "../../scences/Layout";
import { useSelector } from "react-redux";


// redux isnt picking the value from the sessionStorage for some unknown reason, will figure it out later
export const ProtectedRoutes = () => {
  const persistedToken = sessionStorage.getItem("persist:authToken");
  const token = JSON.parse(persistedToken)?.token;
  if (!token) {
    return <Navigate to="/" replace />;
  }

  return <Layout />;


  // return token ? <Layout /> : <Navigate to="/home" replace />
};
