import styled from "styled-components";

export const DashboardSidebarContainer = styled.div`
  @media and screen(width=780px){
  .nav{
  display: none;}
  }`;

export const SidebarNav = styled.nav`
  ul {
    list-style-type: none;
    padding: 10px;
    margin: 10px;
    li {
      margin-right: 10px;
    }
  }
`;

export const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-top: 1.5px solid #000;
  p {
    font-size: 25px;
    font-family: Rubik;
    font-weight: 400;
    line-height: 37.4px;
    letter-spacing: 0.02em;
    margin: 0;
    padding: 0;
  }
  i {
    img {
      margin-right: 10px;
      height: 45px;
      width: 45px;
    }
  }
`;

export const MobileDrawer = styled.div` 
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
`;