import { configureStore } from "@reduxjs/toolkit";
import emailReducer from "./email/emailSlice";
import globalReducer from "./globalSlice";
import userReducer from "./user/userSlice";
import authTokenSlice from "./authTokenSlice";
import sectionsReducer from "./sectionSlice";
import unitsReducer from "./unitsSlice";
import lessonsReducer from "./lessonSlice";
import { apiSlice } from "./apiSlice";
import storageSession from "redux-persist/lib/storage/session";
import { persistStore, persistReducer } from "redux-persist";

const authTokenPersistConfig = {
  key: "authToken",
  storage: storageSession,
};

const store = configureStore({
  reducer: {
    email: emailReducer,
    user: userReducer,
    global: globalReducer,
    authToken: persistReducer(authTokenPersistConfig, authTokenSlice.reducer),
    [apiSlice.reducerPath]: apiSlice.reducer,
    sections: sectionsReducer,
    units: unitsReducer,
    lessons: lessonsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

const persistor = persistStore(store);

export { store, persistor };
