// firebase.js
import firebase from "firebase/app";
import "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC4byNvQ6sirK7MJ6UGr6f96o3rt_y82b8",
  authDomain: "laddrchess.firebaseapp.com",
  projectId: "laddrchess",
  storageBucket: "laddrchess.appspot.com",
  messagingSenderId: "533084834419",
  appId: "1:533084834419:web:a989b40cb2afa1f0c07806",
  measurementId: "G-EZHYKC8P82",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
