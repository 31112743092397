import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import {
  BoardContainer,
  BoardRow,
  PieceImage,
  StyledSquare,
} from "../chess-board/chess-board.styles"; // Ensure these styles are correctly defined
import bb from "../../assets/pieces/bb.png";
import bk from "../../assets/pieces/bk.png";
import bn from "../../assets/pieces/bn.png";
import bp from "../../assets/pieces/bp.png";
import bq from "../../assets/pieces/bq.png";
import br from "../../assets/pieces/br.png";
import wb from "../../assets/pieces/wb.png";
import wk from "../../assets/pieces/wk.png";
import wn from "../../assets/pieces/wn.png";
import wp from "../../assets/pieces/wp.png";
import wq from "../../assets/pieces/wq.png";
import wr from "../../assets/pieces/wr.png";
import { Box } from "@mui/material";
import { ExerciseDescription } from "../../routes/exercise/exercise.styles";
import Feedback from "../feedback/feedback.component";

const pieceImages = {
  b: { k: bk, q: bq, r: br, b: bb, n: bn, p: bp },
  w: { k: wk, q: wq, r: wr, b: wb, n: wn, p: wp },
};

const standardStartingPosition = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

const Square = ({
  children,
  isDark,
  isClickable,
  isLegalMove,
  showRowLabel,
  showColumnLabel,
  rowLabel,
  columnLabel,
  onClick
}) => {
  return (
    <StyledSquare
      $isDark={isDark}
      $isClickable={isClickable}
      $isLegalMove={isLegalMove}
      $showRowLabel={showRowLabel}
      $showColumnLabel={showColumnLabel}
      $rowLabel={rowLabel}
      $columnLabel={columnLabel}
      onClick={onClick}
    >
      {children}
    </StyledSquare>
  );
};

const ChessboardComponent = ({ fen, description='', moves, handleNext, exercises, currentExercise, currentExerciseIndex, orientation }) => {
  const [position, setPosition] = useState({});
  const [currentTurn, setCurrentTurn] = useState("w");
  const [playerColor, setPlayerColor] = useState("w");
  const [feedback, setFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("yellow");
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [selectedSquare, setSelectedSquare] = useState(null);
  const [parsedMoves, setParsedMoves] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isCorrect, setIsCorrect] = useState(true);
  const [freeMove, setFreeMove] = useState(false);
  const [moveTree, setMoveTree] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [noMoreMoves, setNoMoreMoves] = useState(false);
  const exerciseDescription = description[currentExerciseIndex];
  const computerColor = "w"; // Set to "w" if computer is playing white, or "b" if black
  

  useEffect(() => {
    const initialFEN = fen || standardStartingPosition;
    if (isLegalFEN(initialFEN)) {
      setPosition(parseFEN(initialFEN));

      if ((moves && moves.startsWith("1...")) || orientation === "black") {
        setPlayerColor("b");
        setCurrentTurn("b");
      } else {
        setPlayerColor("w");
        setCurrentTurn("w");
      }

      const { tree, flatMoves } = parseMoves(moves);     
      setMoveTree(tree);
      setCurrentNode(tree);
      setParsedMoves(flatMoves);
      setCurrentMoveIndex(0);
      setFreeMove(!moves);

      if (currentTurn === computerColor) {
        executeComputerMove();
      }

      resetFeedback();
    } else {
      setFeedback("Invalid FEN position");
    }

    // Reset the feedback when the currentExerciseIndex changes
    resetFeedback();
    setFeedbackColor("#ffbe00");
  }, [fen, moves]);

  const isLegalFEN = (fen) => {
    const fenParts = fen.split(" ");
    if (fenParts.length !== 6) return false;

    const [position, turn, castling, enPassant, halfMoveClock, fullMoveNumber] = fenParts;
    const rankCount = position.split("/").length;

    if (rankCount !== 8) return false;

    const isValidPosition = position.split("/").every(rank => /^[1-8rnbqkpRNBQKP]+$/.test(rank));
    const isValidTurn = /^(w|b)$/.test(turn);
    const isValidCastling = /^(K?Q?k?q?|[-])$/.test(castling);
    const isValidEnPassant = /^(-|[a-h][36])$/.test(enPassant);
    const isValidHalfMoveClock = /^\d+$/.test(halfMoveClock);
    const isValidFullMoveNumber = /^\d+$/.test(fullMoveNumber);

    return isValidPosition && isValidTurn && isValidCastling && isValidEnPassant && isValidHalfMoveClock && isValidFullMoveNumber;
  };

  const parseFEN = (fen) => {
    const pieces = {};
    const [boardPart] = fen.split(" ");
    const ranks = boardPart.split("/");

    for (let rankIndex = 0; rankIndex < ranks.length; rankIndex++) {
      let file = 0;
      const rank = ranks[rankIndex];

      for (const char of rank) {
        if (parseInt(char)) {
          file += parseInt(char);
        } else {
          const color = char === char.toUpperCase() ? "w" : "b";
          const pieceType = char.toLowerCase();
          const square = String.fromCharCode(97 + file) + (8 - rankIndex);
          pieces[square] = { color, type: pieceType };
          file++;
        }
      }
    }
    return pieces;
  };


  // Other functions remain unchanged

  const generateMoveNotation = (from, to, piece) => {
    const isCapture = position[to] != null;
    let notation = "";

    if (piece.type !== 'p') {
      notation += piece.type.toUpperCase();
    }

    if (isCapture) {
      if (piece.type === 'p') {
        notation += from[0];
      }
      notation += 'x';
    }

    notation += to;

    // Handle pawn promotion (simplified)
    if (piece.type === 'p' && (to[1] === '8' || to[1] === '1')) {
      notation += '=Q'; // Assuming always promote to Queen for simplicity
    }

    return notation;
  };

  const handleUserMove = (from, to, piece) => {

    const userMove = generateMoveNotation(from, to, piece);
    if (checkMove(userMove)) {
      makeMoveOnBoard(from, to, piece);
      setFeedback("Correct move!");
      setCurrentTurn("b");
      setTimeout(() => executeComputerMove(), 10);  // Add delay for natural flow
    } else {
      setFeedback("Incorrect move. Try again.");
    }
  };


  const checkMove = (attemptedMove) => {
    if (!currentNode || currentNode.moves.length === 0) return false;
    for (const expectedMove of currentNode.moves) {
      if (moveMatches(attemptedMove, expectedMove)) {
        setCurrentNode(currentNode.children.find(child => child.move === expectedMove));
        return true;
      }
    }
    return false;
  };

  const makeMoveOnBoard = (from, to, piece) => {
    const updatedPosition = { ...position };
    delete updatedPosition[from];
    updatedPosition[to] = piece;
    setPosition(updatedPosition);
    setSelectedSquare(null);
  };

  // Computer’s move function
const executeComputerMove = () => {
  if (currentNode && currentNode.children.length > 0) {
    const computerMove = currentNode.children[0].move;
    const [from, to] = parseMoveNotation(computerMove);
    makeMoveOnBoard(from, to, position[from]);
    setCurrentTurn(computerColor === "w" ? "b" : "w");  // Switch to the opposite color
    setCurrentNode(currentNode.children[0]);
    setFeedback("Your turn!");
  }
};

const makePlayerMove = (from, to) => {
  makeMoveOnBoard(from, to, position[from]);
  setCurrentTurn(computerColor === "w" ? "w" : "b"); // Switch to computer's turn after player moves
  setFeedback("Computer's turn...");
};
  

  const parseMoveNotation = (notation) => {    
    const from = notation.slice(0, 2);
    const to = notation.slice(2, 4);
  
    return { from, to };
  };


  const moveMatches = (attempted, expected) => {
    expected = expected.replace(/[+#]$/, '');
    if (attempted === expected) return true;
    if (attempted.length === 2 && expected.endsWith(attempted)) return true;
    if (attempted.length === 4 && attempted.includes('x') && expected.endsWith(attempted)) return true;
    return false;
  };


  const parseMoves = (movesString) => {
    if (!movesString) {
      return { tree: null, flatMoves: [] };
    }
  
    // Updated regex to capture moves with check (+) and checkmate (#) as part of the move
    const moveRegex = /([KQRBNP]?[a-h]?[1-8]?x?[a-h][1-8](=[QRBN])?[+#]?|O-O(-O)?|0-0(-0)?)/g;
    const tree = { moves: [], children: [] };
    let currentNode = tree;
    const stack = [];
    const flatMoves = [];
  
    // Remove move numbers and split the string
    const tokens = movesString.replace(/\d+\.+\s*/g, '').split(/\s+/);
  
    tokens.forEach(token => {
      if (token === '(') {
        stack.push(currentNode);
        const newNode = { moves: [], children: [] };
        currentNode.children.push(newNode);
        currentNode = newNode;
      } else if (token === ')') {
        if (stack.length > 0) {
          currentNode = stack.pop();
        }
      } else {
        const moves = token.match(moveRegex);
        if (moves) {
          moves.forEach(move => {
            currentNode.moves.push(move);
            flatMoves.push(move);
          });
        }
      }
    });
  
    return { tree, flatMoves };
  };
  

  const movePiece = (from, to) => {
    const piece = position[from];
    if (!piece || piece.color !== currentTurn) {
      setFeedback("Invalid move: Select your piece.");
      return;
    }

    handleUserMove(from, to, piece);
  
    // Castling logic
    if (piece.type === 'k') {
      if (piece.color === 'w') {
        // White king castling
        if (from === 'e1' && (to === 'g1' || to === 'b1')) {
          if (to === 'g1') {
            // Kingside castling for white
            if (position['f1'] === undefined && position['g1'] === undefined && position['h1'] && position['h1'].type === 'r') {
              const newPosition = { ...position };
              newPosition['g1'] = newPosition['e1'];
              newPosition['f1'] = newPosition['h1'];
              delete newPosition['e1'];
              delete newPosition['h1'];
              setPosition(newPosition);
              setCurrentTurn("b");
              setFeedback("Castling complete!");
              return;
            }
          } else if (to === 'b1') {
            // Queenside castling for white
            if (position['d1'] === undefined && position['c1'] === undefined && position['a1'] && position['a1'].type === 'r') {
              const newPosition = { ...position };
              newPosition['c1'] = newPosition['e1'];
              newPosition['d1'] = newPosition['a1'];
              delete newPosition['e1'];
              delete newPosition['a1'];
              setPosition(newPosition);
              setCurrentTurn("b");
              setFeedback("Castling complete!");
              return;
            }
          }
        }
      } else if (piece.color === 'b') {
        // Black king castling
        if (from === 'e8' && (to === 'g8' || to === 'b8')) {
          if (to === 'g8') {
            // Kingside castling for black
            if (position['f8'] === undefined && position['g8'] === undefined && position['h8'] && position['h8'].type === 'r') {
              const newPosition = { ...position };
              newPosition['g8'] = newPosition['e8'];
              newPosition['f8'] = newPosition['h8'];
              delete newPosition['e8'];
              delete newPosition['h8'];
              setPosition(newPosition);
              setCurrentTurn("w");
              setFeedback("Castling complete!");
              return;
            }
          } else if (to === 'b8') {
            // Queenside castling for black
            if (position['d8'] === undefined && position['c8'] === undefined && position['a8'] && position['a8'].type === 'r') {
              const newPosition = { ...position };
              newPosition['c8'] = newPosition['e8'];
              newPosition['d8'] = newPosition['a8'];
              delete newPosition['e8'];
              delete newPosition['a8'];
              setPosition(newPosition);
              setCurrentTurn("w");
              setFeedback("Castling complete!");
              return;
            }
          }
        }
      }
    }
  
    if (freeMove) {
      const newPosition = { ...position };
      newPosition[to] = newPosition[from];
      delete newPosition[from];
      setPosition(newPosition);
      setCurrentTurn(currentTurn === "w" ? "b" : "w");
      setFeedback("");
      setIsCorrect(true);
      setChecked(true);
      setSelectedSquare(null);
      return; 
    } else {
      const attemptedMove = generateMoveNotation(from, to, piece);
      const isCorrectMove = checkMove(attemptedMove);
  
      if (isCorrectMove) {
        const newPosition = { ...position };
        newPosition[to] = newPosition[from];
        delete newPosition[from];
        setPosition(newPosition);
        setFeedback("Correct move!");
        setIsCorrect(true);
        setFeedbackColor(currentNode.moves.length > currentMoveIndex + 1 ? "#ffbe00" : "#4caf50");
        setCurrentMoveIndex(currentMoveIndex + 1);
        setChecked(true);
  
        if (currentNode.moves.length > currentMoveIndex + 1) {
          setFeedback("Keep going...");
        } else if (currentNode.children.length > 0) {
          setFeedback("Multiple continuations possible. Choose one.");
        } else {
          setFeedback("Great Job!");
        }
      } else {
        setFeedback("Incorrect move. Try again.");
        setIsCorrect(false);
        setFeedbackColor("#ff3737");
        setChecked(true);
      }
    }
  };
  

  const renderPiece = (piece) => {
    if (!piece) return null;
    const { color, type } = piece;
    return <PieceImage src={pieceImages[color][type]} alt={`${color} ${type}`} />;
  };

  const handleSquareClick = (square) => {
    if (selectedSquare && moves) {
      movePiece(selectedSquare, square);
      setSelectedSquare(null);
    } else {
      const piece = position[square];
      if (piece && piece.color === currentTurn) {
        setSelectedSquare(square);
      }
    }
  };

  const resetFeedback = () => {
    setChecked(false);
    setIsCorrect(true);
    setFeedback("");
    setFeedbackColor("#4caf50");
  };

  return (
    <>
      <BoardContainer>
        {Array(8)
          .fill(null)
          .map((_, rowIndex) => (
            <BoardRow key={rowIndex}>
              {Array(8)
                .fill(null)
                .map((_, colIndex) => {
                  const adjustedRowIndex = playerColor === "w"
                    ? rowIndex
                    : 7 - rowIndex; // Flip for black
                  const adjustedColIndex = playerColor === "w"
                    ? colIndex
                    : 7 - colIndex; // Flip columns for black

                  const square = String.fromCharCode(97 + adjustedColIndex) + (8 - adjustedRowIndex);
                  const piece = position[square];

                  return (
                    <Square
                      key={square}
                      onClick={() => handleSquareClick(square)}
                      isDark={(adjustedRowIndex + adjustedColIndex) % 2 === 1}
                      rowLabel={playerColor === "w" ? 8 - rowIndex : 1 + rowIndex} // Flip row labels for black
                      columnLabel={playerColor === "w" ? String.fromCharCode(97 + colIndex) : String.fromCharCode(104 - colIndex)} // Flip column labels for black
                      showRowLabel={colIndex === 0}
                      showColumnLabel={rowIndex === 7}
                    >
                      {renderPiece(piece)}
                    </Square>
                  );
                })}
            </BoardRow>
          ))}
      </BoardContainer>
      {
        exerciseDescription ?
          <ExerciseDescription>{description}</ExerciseDescription> : null
      }
      {currentExercise.type !== "objective_type" && currentExercise.type !== "game_type" ? (
        <Feedback
          currentExerciseIndex={currentExerciseIndex}
          handleNext={handleNext}
          isCorrect={isCorrect}
          exercises={exercises}
          feedbackColor={feedbackColor}
          feedback={feedback}
        />
      ) : null}

    </>
  );
};

export default ChessboardComponent;
